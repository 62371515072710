<template>
  <validation-observer ref="simpleRules">
    <b-row v-if="isBackBtn">
      <b-col>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          class="mr-1 mb-1"
          @click.prevent="goBack"
        >
          Back
        </b-button>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <div id="component-breadcrumbs">
          <b-breadcrumb
            class="breadcrumb-slash"
            :items="breadcrumbItems"
          />
        </div>
      </b-col>
      <b-col v-if="caseObject.Status !== 'Closed' && !caseObject.ClosedByUserID && readOnly && mainTab === 'ENTER_CASE' && !caseObject.IsLegalHold && UserType === 'User'">
        <div class="float-right mr-1">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="primary"
            class="mr-1"
            @click.prevent="editScreen()"
          >
            Edit
          </b-button>
        </div>
      </b-col>
      <b-col v-if="UserType === 'Client' && clientReadOnly && mainTab === 'ENTER_CASE'">
        <div class="float-right mr-1">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="primary"
            class="mr-1"
            @click.prevent="clientEdit()"
          >
            Edit
          </b-button>
        </div>
      </b-col>
      <b-col v-if="caseObject.Status !== 'Closed' && mainTab === 'ENTER_SUBJECT' && UserType !== 'Client' && onLine">
        <div class="float-right mr-1 link">
          <router-link
            v-if="getSubject.SubjectID > 0"
            :to="'/subject/' + getSubject.SubjectID"
          >View Subject Page - {{ getSubject.FirstName + ' ' + getSubject.MiddleName + ' ' + getSubject.LastName }}</router-link>
        </div>
      </b-col>
    </b-row>

    <b-row v-if="caseObject.CaseID">
      <b-col>
        <h2 class="text-nowrap">
          Case: {{ caseObject.FileNumber }} - {{ caseObject.Status }}
        </h2>
        <div
          v-if="UserType !== 'Client'"
          class="link"
        >
          <router-link
            v-if="getSubject.SubjectID > 0 && onLine"
            :to="'/subject/' + getSubject.SubjectID"
          >Subject: {{ getSubject.FirstName + ' ' + getSubject.MiddleName + ' ' + getSubject.LastName }}</router-link>
          <div v-else-if="getSubject.SubjectID > 0">Subject: {{ getSubject.FirstName + ' ' + getSubject.MiddleName + ' ' + getSubject.LastName }}</div>
        </div>
      </b-col>
      <b-col>
        <div class="hold-wrapper">
          <div v-if="UserType === 'User'" class="hold-btn">
            <div class="img-hold">
              <img src="../../assets/images/logo/historyHold.png">
            </div>
            <router-link
              :to="'/cases/case-log-history/' + caseObject.CaseID"
              class="text-white hover-display text-nowrap"
            >View Case History</router-link>
          </div>

          <div v-if="UserType === 'User'" class="hold-btn">
            <div class="img-hold">
              <img src="../../assets/images/logo/scales.png">
            </div>
            <span
              class="text-white hover-display text-nowrap"
              @click="legalHoldModal()"
            >
              <span v-if="caseObject.IsLegalHold">Remove Legal Hold</span>
              <span v-else>Add Legal Hold</span>
            </span>
          </div>

          <div
            v-if="getCurrentScopes.indexOf('st2.exportreport') !== -1"
            class="hold-btn"
          >
            <div class="img-hold">
              <img src="../../assets/images/logo/exportReport.png">
            </div>
            <span
              class="text-white hover-display text-nowrap"
              @click="exportReport()"
            >
              <span>Export Report</span>
            </span>
          </div>

          <div v-if="UserType === 'User'" class="hold-btn">
            <div 
              v-if="caseObject.ClosedByUserID" 
              class="img-hold"
            >
              <img src="../../assets/images/logo/reopenCase.png">
            </div>
            <div 
              v-else 
              class="img-hold"
            >
              <img src="../../assets/images/logo/closeCase.png">
            </div>
            <span
              class="text-white hover-display text-nowrap"
              @click="closeReopenCase()"
            >
              <span v-if="caseObject.Status === 'Closed'">Reopen Case</span>
              <span v-else>Close Case</span>
            </span>
          </div>

          <div v-if="UserType === 'User'" class="hold-btn">
            <div class="img-hold">
              <img src="../../assets/images/logo/Invoice.png">
            </div>
            <span
              class="text-white hover-display text-nowrap"
              @click="addInvoice()"
            >
              <span>Add Invoice</span>
            </span>
          </div>

          <div v-if="UserType === 'User'" class="hold-btn">
            <div class="img-hold">
              <img src="../../assets/images/logo/Video.png">
            </div>
            <span
              class="text-white hover-display text-nowrap"
              @click="caseVideo()"
            >
              <span>Case Video</span>
            </span>
          </div>

          <div
            v-if="getCurrentScopes.indexOf('st2.betafeatures') !== -1 && UserType === 'User'"
            class="hold-btn"
          >
            <div class="img-hold">
              <img src="../../assets/images/logo/Merge.png">
            </div>
            <span
              class="text-white hover-display text-nowrap"
              @click="mergeCaseVideo()"
            >
              <span>Merge Case Video</span>
            </span>
          </div>

          <div v-if="UserType === 'User'" class="hold-btn">
            <div class="img-hold">
              <feather-icon
                icon="ImageIcon"
                size="21"
                style="color: white"
              />
            </div>
            <span
              class="text-white hover-display text-nowrap"
              @click="caseMedia()"
            >
              <span>Case Media</span>
            </span>
          </div>

        </div>

        <div
          v-if="UserType === 'Client' && caseObject.Status === 'Closed'"
          class="hold-wrapper"
        >
          <div class="hold-btn hold-btn-open">
            <div class="img-hold">
              <img src="../../assets/images/logo/reopenCase.png">
            </div>
            <span
              class="text-white hover-display text-nowrap"
              @click="closeReopenCase()"
            >
              <span>Reopen Case</span>
            </span>
          </div>
        </div>
      </b-col>
      <b-col />
    </b-row>
    <b-row v-if="caseObject.IsLegalHold">
      <b-col>
        <h2><b
          class="text-nowrap"
          style="color:red"
        >
          Legal Hold
        </b></h2>
      </b-col>
    </b-row>
    <hr>
    <div class="main-tab">
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        type="button"
        :variant="btnVariant('ENTER_CASE')"
        class="mr-1"
        @click.prevent="openMainTab('ENTER_CASE')"
      >
        {{ caseObject.CaseID ? "Case Info" : "Enter Case Info" }}
      </b-button>
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        type="button"
        :variant="btnVariant('ENTER_SUBJECT')"
        class="mr-1"
        @click.prevent="openMainTab('ENTER_SUBJECT')"
      >
        {{ caseObject.CaseID ? "Subject" : "Enter Subject" }}
      </b-button>
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        type="button"
        :variant="btnVariant('UPLOAD_CASE')"
        class="mr-1"
        @click.prevent="openMainTab('UPLOAD_CASE')"
      >
        {{ caseObject.CaseID ? "Case Files" : "Upload Case Files" }}
      </b-button>
      <!-- IF CASE SUBMITTED -->
      <b-button
        v-if="!isPND && caseObject.CaseID"
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        type="button"
        :variant="btnVariant('ACTIONS')"
        class="mr-1"
        @click.prevent="openMainTab('ACTIONS')"
      >
        Actions
      </b-button>
      <b-button
        v-if="!isPND && caseObject.CaseID && UserType !== 'Client'"
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        type="button"
        :variant="btnVariant('INSTRUCTIONS')"
        class="mr-1"
        @click.prevent="openMainTab('INSTRUCTIONS')"
      >
        Instructions
      </b-button>
      <b-button
        v-if="!isPND && caseObject.CaseID"
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        type="button"
        :variant="btnVariant('ACTION_LOG')"
        class="mr-1"
        @click.prevent="openMainTab('ACTION_LOG')"
      >
        Case Log
      </b-button>
      <b-button
        v-if="!isPND && caseObject.CaseID && UserType !== 'Client'"
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        type="button"
        :variant="btnVariant('RELATED_CASES')"
        class="mr-1"
        @click.prevent="openMainTab('RELATED_CASES')"
      >
        Related Cases
      </b-button>
      <div
        v-if="mainTab === 'ENTER_SUBJECT' || mainTab === 'ENTER_CASE'"
        class="collapse-btn-block"
      >
        <b-button
          class="collapse-btn"
          @click.prevent="openAllTab(false)"
        >
          <img class="collapse-btn-img" src="../../assets/images/icons/collapse.png">
        </b-button>
        <b-button
          class="collapse-btn"
          @click.prevent="openAllTab(true)"
        >
          <img class="collapse-btn-img" src="../../assets/images/icons/arrow.png">
        </b-button>
      </div>
    </div>

    <div
      v-if="mainTab === 'ENTER_CASE'"
      class="custom-p-2"
    >

      <div
        v-if="getUserType !== 'Investigator' || getCurrentScopes.indexOf('st2.viewclientinfo') !== -1"
        class="accordion"
      >
        <div
          class="accordion-title"
          @click="toggleAccordion('CLIENT')"
        >
          <div
            class="collapse-arrow"
            :class="{ open: tab.CLIENT }"
          />
          <h3>Client</h3>
        </div>
        <b-row v-if="tab.CLIENT">
          <b-col>
            <CaseInfoClient
              @changedBranch="changedBranch"
              @changedClient="changedClient"
              @changedRequester="changedRequester"
              @changedCaseID="changedCaseID"
              @changedCcEmail="changedCcEmail"
            />
          </b-col>
        </b-row>
      </div>

      <div class="accordion">
        <div
          class="accordion-title"
          @click="toggleAccordion('ASSIGNMENT_INFORMATION')"
        >
          <div
            class="collapse-arrow"
            :class="{ open: tab.ASSIGNMENT_INFORMATION }"
          />
          <h3>Assignment Information</h3>
        </div>
        <b-row v-if="tab.ASSIGNMENT_INFORMATION">
          <b-col>
            <CaseInfoAssignmentInformation
              @changeAssignment="changeAssignment"
              @emptyField="emptyField"
            />
          </b-col>
        </b-row>
      </div>

      <div class="accordion">
        <div
          class="accordion-title"
          @click="toggleAccordion('CLAIM_DETAILS')"
        >
          <div
            class="collapse-arrow"
            :class="{ open: tab.CLAIM_DETAILS }"
          />
          <h3>Claim Details</h3>
        </div>
        <b-row v-if="tab.CLAIM_DETAILS">
          <b-col>
            <CaseInfoClaimDetails @changeClaimDetail="changeClaimDetail" />
          </b-col>
        </b-row>
      </div>

      <div class="accordion">
        <div
          class="accordion-title"
          @click="toggleAccordion('SURVEILLANCE_CONDUCTED')"
        >
          <div
            class="collapse-arrow"
            :class="{ open: tab.SURVEILLANCE_CONDUCTED }"
          />
          <h3>Case Notes</h3>
        </div>
        <b-row v-if="tab.SURVEILLANCE_CONDUCTED">
          <b-col>
            <SurveillanceConducted
              @changedSurveillanceConducted="changedSurveillanceConducted"
            />
          </b-col>
        </b-row>
      </div>

      <div
        v-if="UserType === 'User' || (UserType === 'Investigator' && getCurrentScopes.indexOf('st2.trustedinvestigator') !== -1)"
        class="accordion"
      >
        <div
          class="accordion-title"
          @click="toggleAccordion('INSURED')"
        >
          <div
            class="collapse-arrow"
            :class="{ open: tab.INSURED }"
          />
          <h3>Insured</h3>
        </div>
        <b-row v-if="tab.INSURED">
          <b-col>
            <CaseInfoInsured @changedInsured="changedInsured" />
          </b-col>
        </b-row>
      </div>

      <div
        v-if="UserType === 'User' || (UserType === 'Investigator' && getCurrentScopes.indexOf('st2.trustedinvestigator') !== -1)"
        class="accordion"
      >
        <div
          class="accordion-title"
          @click="toggleAccordion('CASE_MANAGEMENT')"
        >
          <div
            class="collapse-arrow"
            :class="{ open: tab.CASE_MANAGEMENT }"
          />
          <h3>Case Management</h3>
        </div>
        <b-row v-if="tab.CASE_MANAGEMENT">
          <b-col>
            <CaseInfoManagement
              @changedOperationsManager="changedOperationsManager"
            />
          </b-col>
        </b-row>
      </div>
    </div>

    <div
      v-if="mainTab === 'ENTER_SUBJECT'"
      class="custom-p-2"
    >
      <div class="accordion">
        <div
          class="accordion-title"
          @click="toggleAccordion('SUBJECT_DETAILS')"
        >
          <div
            class="collapse-arrow"
            :class="{ open: tab.SUBJECT_DETAILS }"
          />
          <h3>Subject Details</h3>
        </div>
        <b-row v-if="tab.SUBJECT_DETAILS">
          <b-col>
            <SubjectDetails
              @changeSubject="changeSubject"
              @reloadData="reloadData"
              @uploadSubjectImage="uploadSubjectImage"
            />
          </b-col>
        </b-row>
      </div>

      <div class="accordion">
        <div
          class="accordion-title"
          @click="toggleAccordion('SUBJECT_LOCATION')"
        >
          <div
            class="collapse-arrow"
            :class="{ open: tab.SUBJECT_LOCATION }"
          />
          <h3>Subject Location</h3>
        </div>
        <b-row v-if="tab.SUBJECT_LOCATION">
          <b-col>
            <SubjectLocation @changedSubjectLocation="changedSubjectLocation" />
          </b-col>
        </b-row>
      </div>

      <div class="accordion">
        <div
          class="accordion-title"
          @click="toggleAccordion('SUBJECT_DESCRIPTION')"
        >
          <div
            class="collapse-arrow"
            :class="{ open: tab.SUBJECT_DESCRIPTION }"
          />
          <h3>Subject Description</h3>
        </div>
        <b-row v-if="tab.SUBJECT_DESCRIPTION">
          <b-col>
            <SubjectDescription
              @changedSubjectDescription="changedSubjectDescription"
            />
          </b-col>
        </b-row>
      </div>
    </div>

    <div 
      v-if="mainTab === 'UPLOAD_CASE'"
      class="custom-p-2"
    >
      <div>
        <b-row>
          <b-col>
            <UploadeCaseFiles />
          </b-col>
        </b-row>
      </div>
    </div>

    <div
      v-if="mainTab === 'ACTIONS'"
      class="custom-p-2"
    >
      <div>
        <b-row>
          <b-col>
            <ActionsList />
          </b-col>
        </b-row>
      </div>
    </div>

    <div
      v-if="mainTab === 'INSTRUCTIONS'"
      class="custom-p-2"
    >
      <div>
        <b-row>
          <b-col>
            <Instructions />
          </b-col>
        </b-row>
      </div>
    </div>

    <div
      v-if="mainTab === 'ACTION_LOG'"
      class="custom-p-2"
    >
      <div>
        <b-row>
          <b-col>
            <ActionLog />
          </b-col>
        </b-row>
      </div>
    </div>

    <div
      v-if="mainTab === 'RELATED_CASES'"
      class="custom-p-2"
    >
      <div>
        <b-row>
          <b-col>
            <CaseInfoRelatedCases />
          </b-col>
        </b-row>
      </div>
    </div>

    <b-row class="pb-2 pt-2 mt-2">
      <b-col>
        <div class="d-flex justify-content-between">
          <div>
            <b-button
              v-if="!readOnly && caseObject.CaseID"
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="reset"
              variant="outline-secondary"
              @click="resetForm()"
            >
              Cancel
            </b-button>
            <b-button
              v-if="mainTab !== 'ENTER_CASE' && UserType !== 'Investigator'"
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="reset"
              variant="link"
              style="margin-left: 1rem"
              class="btn-link-box"
              @click="previousForm()"
            >
              Previous
            </b-button>
          </div>
          <div v-if="mainTab === 'ENTER_SUBJECT' && !readOnly && UserType === 'User' && isPND">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="success"
              class="mr-1"
              @click="submitCase()"
            >
              Submit Case
            </b-button>
          </div>
          <div v-else-if="caseObject.Status !== 'Closed' && !readOnly && (mainTab === 'ENTER_SUBJECT' || mainTab === 'ENTER_SUBJECT' && !clientReadOnly)">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="success"
              :disabled="blockSaveBtn"
              @click.prevent="nextForm()"
            >
              Save &#38; Continue
            </b-button>
          </div>
          <div v-else-if="(mainTab !== 'UPLOAD_CASE' && isPND) || (!isPND && mainTab !== 'RELATED_CASES')">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="link"
              class="mr-1 btn-link-box"
              @click.prevent="nextForm()"
            >
              Next
            </b-button>
          </div>
        </div>
      </b-col>
    </b-row>
    <b-modal 
      v-model="modalShow" 
      hide-backdrop 
      size="md" 
      centered
    >
      <template #modal-header="">
        <div class="d-flex mt-1 mb-n3 text-center flex-column justify-content-center">
          <h2 class="text-center">
            <strong>
              You are about to
              <span v-if="caseObject.IsLegalHold">remove</span>
              <span v-else>add</span>
              a legal hold
              <span v-if="caseObject.IsLegalHold">from</span>
              <span v-else>to</span>
              this case.
            </strong>
          </h2>
          <span>Are you sure you wish to continue?</span>
        </div>
      </template>

      <template #modal-footer="">
        <div class="d-flex mb-1 justify-content-center flex-grow-1">
          <div class="w-50 d-flex justify-content-center">
            <b-button
              type="reset"
              variant="outline-secondary"
              size="md"
              @click="chancelLegalHold()"
            >
              Cancel
            </b-button>
          </div>
          <div class="w-50 d-flex justify-content-center">
            <b-button
              type="submit"
              variant="primary"
              class="mr-1"
              size="md"
              @click="changeLegalHold()"
            >
              <span v-if="caseObject.IsLegalHold">Remove Legal Hold</span>
              <span v-else>Add Legal Hold</span>
            </b-button>
          </div>
        </div>
      </template>
    </b-modal>
  </validation-observer>
</template>

<script>
import { BRow, BCol, BButton, BBreadcrumb } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import "vue-good-table/dist/vue-good-table.css";
import { required } from "@validations";
import store from "@/store";
import { mapGetters, mapMutations } from "vuex";
import APIService from "@core/utils/APIService";
import CaseInfoClient from "@/views/cases/components/CaseInfoClient";
import CaseInfoAssignmentInformation from "@/views/cases/components/CaseInfoAssignmentInformation";
import CaseInfoClaimDetails from "@/views/cases/components/CaseInfoClaimDetails";
import SubjectDetails from "@/views/cases/components/SubjectDetails";
import SubjectLocation from "@/views/cases/components/SubjectLocation";
import SubjectDescription from "@/views/cases/components/SubjectDescription";
import SurveillanceConducted from "@/views/cases/components/SurveillanceConducted";
import UploadeCaseFiles from "@/views/cases/components/UploadeCaseFiles";
import CaseInfoInsured from "@/views/cases/components/CaseInfoInsured";
import CaseInfoManagement from "@/views/cases/components/CaseInfoManagement";
import {ValidationObserver} from "vee-validate";
import ActionLog from "@/views/cases/components/ActionLog";
import Instructions from "@/views/cases/components/Instructions";
import ActionsList from "@/views/cases/components/ActionsList";
import CaseInfoRelatedCases from "@/views/cases/components/CaseInfoRelatedCases";
import Navbar from "@/layouts/components/Navbar";
import { tryGetDate } from '@core/utils/filter';


const apiService = new APIService();

export default {
  components: {
    CaseInfoRelatedCases,
    ActionsList,
    ActionLog,
    ValidationObserver,
    CaseInfoManagement,
    CaseInfoInsured,
    UploadeCaseFiles,
    SurveillanceConducted,
    SubjectDescription,
    SubjectLocation,
    SubjectDetails,
    CaseInfoClaimDetails,
    CaseInfoAssignmentInformation,
    CaseInfoClient,
    Instructions,
    BRow,
    BCol,
    BButton,
    BBreadcrumb,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      caseObject: {},
      oldCaseObject: {},
      oldReadOnlyState: false,
      errors: "",
      readOnly: false,
      clientReadOnly: false,
      blockSaveBtn: false,
      oldOperationsManagerUserID: "",

      UserType: "",
      required,
      isBackBtn: false,
      mainTab: "ENTER_CASE",
      mainTabs: ["ENTER_CASE", "ENTER_SUBJECT", "UPLOAD_CASE", "ACTIONS", "INSTRUCTIONS", "ACTION_LOG", "RELATED_CASES"],
      emptyField: false,
      isPND: false,
      tab: {
        CLIENT: true,
        ASSIGNMENT_INFORMATION: true,
        CLAIM_DETAILS: true,
        INSURED: true,
        CASE_MANAGEMENT: true,
        SUBJECT_DETAILS: true,
        SUBJECT_LOCATION: true,
        SUBJECT_DESCRIPTION: false,
        SURVEILLANCE_CONDUCTED: true,
      },

      onLine: navigator.onLine,
      formSubjectData: "",

      modalShow: false,
      tryGetDate,
    }
  },
  computed: {
    ...mapGetters({
      getCase: "cases/getCase",
      getSubject: "cases/getSubject",
      getPostData: "cases/getPostData",
      getUserType: "auth/getUserType",
      getUserRole: "auth/getUserRole",
      getCurrentClient: "cases/getCurrentClient",
      getNotification: "notification/getNotification",
      getCurrentScopes: "scopes/getCurrentScopes",
      getOnlineStatus: "offline/getOnlineStatus",
    }),
    getUserTypeData() {
      return this.getUserType;
    },
    breadcrumbItems: function() {
      if (this.onLine) return [
        {
          text: this.getUserType === 'User' ? 'Cases' : 'Home',
          href: this.getUserType === 'User' ? '/cases' : '/home'
        },
        {
          text: this.caseObject.CaseID ? this.readOnly ? 'View Case' : 'Edit Case' : 'Create Case',
        },
        {
          text: this.caseObject.CaseID ? this.caseObject.FileNumber : "",
        },
      ]
      return [
        {
          text: this.getUserType === 'User' ? 'Cases' : 'Home',
        },
        {
          text: this.caseObject.CaseID ? this.readOnly ? 'View Case' : 'Edit Case' : 'Create Case',
        },
        {
          text: this.caseObject.CaseID ? this.caseObject.FileNumber : "",
        },
      ]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true;
        return this.dir;
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false;
      return this.dir;
    },
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Current: "light-primary",
        Professional: "light-success",
        Rejected: "light-danger",
        Resigned: "light-warning",
        Applied: "light-info",
        /* eslint-enable key-spacing */
      };

      return (status) => statusColor[status];
    },
  },
  watch: {
    getOnlineStatus(val) {
      this.onLine = val;
    },
    getUserTypeData(val) {
      this.setUserType(val)
    },
    mainTab(val) {
      if (val === "ENTER_SUBJECT" && this.caseObject.CaseID) {
        this.setIsReadOnly(true)
      } else {
        this.setIsReadOnly(this.oldReadOnlyState)
      }
    }
  },
  async created() {
    this.setUserType(this.getUserType);
    this.emptyCase();
    try {
      this.isBackBtn = this.$route.meta.myCases;
      const response = await apiService.get('users/user').then(res => res.data).catch(error => error)
      this.dealOwner = this.CSRoption = response.Users.map(d => ({title: d.LastName + ", " + d.FirstName, value: d.UserAccountID}))
      if (this.dealOwner && this.CSRoption) {
        var existingCaseID = this.$route.params.caseId;
        var KeyTab = this.$route.params.keyTab;
        if (existingCaseID) {
          this.dataLoad(existingCaseID, KeyTab)
        }
      }
    } catch (err) {
      this.error = err;
    }
  },

  mounted() {
    this.setIsReadOnly(false);
    this.caseObject = this.getCase;
  },

  beforeDestroy() {
    this.setCurrentClient({})
  },

  methods: {
    ...mapMutations({
      setCase: "cases/setCase",
      emptyCase: "cases/emptyCase",
      setIsReadOnly: "cases/setIsReadOnly",
      setClientReadOnly: "cases/setClientReadOnly",
      setAllSubjectTabOpen: "cases/setAllSubjectTabOpen",
      setAllCaseTabOpen: "cases/setAllCaseTabOpen",
      setCurrentClient: "cases/setCurrentClient",
    }),
    showToast(variant, position, timeout, data) {
      this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Notification',
              icon: 'InfoIcon',
              text: data,
              variant,
            },
          },
          {
            position,
            timeout,
          })
    },
    goBack() {
      this.$router.go(-1)
    },
    reloadData() {
      this.dataLoad(this.caseObject.CaseID);
    },
    dataLoad(CaseID, KeyTab = "") {
      apiService
          .get("case/" + CaseID + "/detail")
          .then((response) => {
            if (response && response.data) {
              const Appointments = [{
                ScheduledApptWhen: response.data.ScheduledApptWhen,
                ScheduledApptWhere: response.data.ScheduledApptWhere,
              }];
              response.data = {...response.data, Appointments}
              this.caseObject = response.data;
              this.oldOperationsManagerUserID = response.data.OperationsManagerUserID
              this.oldReadOnlyState = this.readOnly = true;
              this.isPND = !!response.data.FileNumber.toUpperCase().includes('PEND');
              this.setIsReadOnly(this.readOnly);
              this.setCase(response.data);
              this.oldCaseObject = this.getPostData;
              if (this.formSubjectData) {
                this.uploadSubjectImage(this.formSubjectData);
                this.formSubjectData = "";
              }
              if (this.UserType === 'Investigator') {
                KeyTab = "ACTIONS"
              }
              if (KeyTab) {
                this.openMainTab(KeyTab);
              }
              if (this.UserType === 'Client') {
                if (this.getUserRole === 'Client Supervisor' || this.getUserRole === 'Branch Supervisor') {
                  this.clientReadOnly = true;
                  this.setClientReadOnly(this.clientReadOnly)
                }
              }
            }
          });
    },
    resetForm() {
      if (this.caseObject.CaseID) {
        this.oldReadOnlyState = this.readOnly = true;
        this.setIsReadOnly(this.readOnly);
        this.reloadData();
      }
    },
    processForm(tab) {
      let postData = this.getPostData;
      if (postData.ClaimFileType) {
        postData.ClaimFileType = postData.ClaimFileType.value;
      }
      var endpoint = 'case/create';
      if (postData.CaseID) {
        if (!postData.ReceivedSource && !this.readOnly) {
          this.showToast('danger', 'top-center', 4000, JSON.stringify('Please provide all required fields: Case Received Source'))
          return;
        }
        endpoint = 'case/' + postData.CaseID;
      }
      this.blockSaveBtn = true;
      if (postData.Subject.DateOfBirth) {
        postData.Subject.DateOfBirth = this.tryGetDate(postData.Subject.DateOfBirth)
      }

      apiService.post(endpoint, postData).then(response => {
        if (response) {
          this.blockSaveBtn = false;
          this.clientReadOnly = true;
          this.setClientReadOnly(this.clientReadOnly)
          this.showToast('success', 'top-center', 4000, 'Case Saved!');
          this.mainTab = tab;
          if (response && typeof response.data === 'object' && !response.data.success) {
            this.showToast('warning', 'top-center', 5000, response.data.message);
          }
          if (endpoint.indexOf('create') !== -1) {
            window.location = '/cases/' + response.data.data + "/detail/UPLOAD_CASE";
          } else {
            this.dataLoad(this.caseObject.CaseID, tab);
          }
        }
      })
    },
    submitCase() {
      const currentTab = this.mainTabs.indexOf(this.mainTab);
      if (this.validateToggleMainTab()) {
        if (this.validateToSave()) {
          this.processForm(this.mainTabs[currentTab + 1])
        }
      }
      let postData = this.getPostData;
      var endpoint;
      if (this.caseObject.CaseID) {
        endpoint = 'case/' + this.caseObject.CaseID + '/submit';
      }
      apiService.post(endpoint, postData).then(res => {
        if (res) {
          this.dataLoad(this.caseObject.CaseID);
          this.showToast('success', 'top-center', 4000, 'Case Submitted!');
          this.isPND = false;
        }
      })
    },
    toggleAccordion(tab) {
      this.tab[tab] = !this.tab[tab]
    },
    openMainTab(tab) {
      if (this.UserType === 'Investigator') {
        this.mainTab = tab === "ENTER_CASE" || tab === "ENTER_SUBJECT" || tab === "ACTION_LOG" || tab === "ACTIONS" || tab === "UPLOAD_CASE" || tab === "INSTRUCTIONS" || tab === "RELATED_CASES"?
            tab : this.mainTab === "ENTER_CASE" || this.mainTab === "ACTION_LOG" || this.mainTab === "ENTER_SUBJECT" || this.mainTab === "ACTIONS" || tab === "UPLOAD_CASE" || tab === "INSTRUCTIONS" || this.mainTab === "RELATED_CASES" ?
                this.mainTab : "ACTIONS"
        return;
      }
      if (this.UserType === 'Client') {
        tab = tab === "INSTRUCTIONS" || tab === "RELATED_CASES" ? "ACTION_LOG" : tab;
      }
      if (this.readOnly) {
        this.mainTab = tab;
      } else if (tab === "UPLOAD_CASE") {
        if (!this.caseObject.CaseID) return;
        if (this.validateToSave() && this.validateToggleMainTab()) {
          this.processForm(tab)
        }
      } else if (this.validateToggleMainTab()) {
        this.mainTab = tab;
      }
    },
    openAllTab(toggle) {
      if (this.mainTab === "ENTER_CASE") {
        this.setAllCaseTabOpen(toggle ? 1 : 0)
        this.tab.CASE_MANAGEMENT = toggle;
        this.tab.CLIENT = toggle;
        this.tab.ASSIGNMENT_INFORMATION = toggle;
        this.tab.CLAIM_DETAILS = toggle;
        this.tab.INSURED = toggle;
        this.tab.SURVEILLANCE_CONDUCTED = toggle;
      } else if (this.mainTab === "ENTER_SUBJECT") {
        this.setAllSubjectTabOpen(toggle ? 1 : 0)
        this.tab.SUBJECT_DETAILS = toggle;
        this.tab.SUBJECT_LOCATION = toggle;
        this.tab.SUBJECT_DESCRIPTION = toggle;
      }

    },
    btnVariant(tab) {
      return this.mainTab === tab ? "primary" : "none"
    },
    nextForm(forRequired = false) {
      const currentTab = this.mainTabs.indexOf(this.mainTab);
      if (this.readOnly && this.clientReadOnly) {
        this.openMainTab(this.mainTabs[currentTab + 1]);
      } else if (this.validateToggleMainTab(forRequired)) {
        if (this.mainTabs[currentTab + 1] === "UPLOAD_CASE") {
          if (this.validateToSave(forRequired)) {
            this.processForm(this.mainTabs[currentTab + 1])
          }
        } else {
          this.openMainTab(this.mainTabs[currentTab + 1]);
        }
      }
    },

    validateEmail(emailAdress) {
      let regexEmail = /\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/;
      return !!emailAdress.match(regexEmail);
    },

    validateCcEmail(val) {
      let ErrorMsg = false;
      if (val) {
        const itemArr = val.split(";")
        itemArr.map(item => {
          if (item && item.trim() && !this.validateEmail(item)) {
            ErrorMsg = true;
          }
        })
      }
      return ErrorMsg
    },

    validateToSave(forRequired = false) {
      const currentDate = new Date()
      let errorMsg = "";
      errorMsg = this.getSubject.FirstName ? "" : "First Name, ";
      errorMsg = this.getSubject.LastName ? errorMsg + "" : errorMsg + "Last Name, ";
      errorMsg = this.getSubject.SubjectAddress[0].Address1 ? errorMsg + "" : errorMsg + "Street Address, ";
      errorMsg = this.getSubject.SubjectAddress[0].City ? errorMsg + "" : errorMsg + "City, ";
      errorMsg = this.getSubject.SubjectAddress[0].PostalCode ? errorMsg + "" : errorMsg + "State, ";
      errorMsg = this.getSubject.SubjectAddress[0].StateProvince ? errorMsg + "" : errorMsg + "Zip, ";
      if (errorMsg) {
        if (forRequired) this.showToast('danger', 'top-center', 4000, JSON.stringify('Please provide all required fields: ' + errorMsg))
        this.tab["SUBJECT_LOCATION"] = true;
        this.tab["SUBJECT_DETAILS"] = true;
        if (!forRequired) {
          setTimeout(() => {
            this.nextForm(true);
          }, 100)
        }
      }
      if (this.getSubject.DateOfBirth && (Number(this.getSubject.DateOfBirth.substring(0, 4)) <= 1900 || new Date(this.getSubject.DateOfBirth) >= currentDate)) {
        this.showToast('danger', 'top-center', 4000, 'DOB must be a valid date.');
        return false
      }
      return !errorMsg;
    },
    previousForm() {
      const currentTab = this.mainTabs.indexOf(this.mainTab);
      this.mainTab = this.mainTabs[currentTab - 1]
    },
    validateToggleMainTab(forRequired = false) {
      this.$refs.simpleRules.validate()
      let errorMsg = "";
      errorMsg = this.caseObject.Branch.Client.Name ? "" : "Client Name, ";
      errorMsg = this.caseObject.Branch.Name ? errorMsg + "" : errorMsg + "Branch Name, ";
      errorMsg = this.caseObject.Branch.Requester.Name ? errorMsg + "" : errorMsg + "Requester Name, ";
      errorMsg = this.caseObject.RequestedServices ? errorMsg + "" : errorMsg + "Services, ";
      errorMsg = this.caseObject.CaseID || this.caseObject.ReceivedSource ? errorMsg + "" : errorMsg + "Case Received Source, ";
      errorMsg = this.caseObject.BudgetHours ? errorMsg + "" : this.caseObject.BudgetHours === 0 ? errorMsg + "" : errorMsg + "Budget Hours";
      errorMsg = this.validateCcEmail(this.caseObject.CcEmails) ? errorMsg + "CC Email" : errorMsg + "";

      if (errorMsg) {
        if (forRequired) this.showToast('danger', 'top-center', 4000, JSON.stringify('Please provide all required fields: ' + errorMsg));
        this.tab["CLIENT"] = true;
        if (!this.caseObject.RequestedServices || !this.caseObject.BudgetHours || !this.caseObject.ReceivedSource) {
          if (!this.caseObject.RequestedServices) this.emptyField = true;
          this.tab["ASSIGNMENT_INFORMATION"] = true;
          if (!forRequired) {
            setTimeout(() => {
              this.nextForm(true);
            }, 100)
          }
        } else {
          this.emptyField = false;
        }
      }
      return !errorMsg;
    },

    changedBranch(val) {
      this.caseObject.Branch = {...this.caseObject.Branch, ...val};
      this.setCase(this.caseObject);
    },
    changedCaseID(val) {
      this.caseObject = {...this.caseObject, CaseID: val};
      this.setCase(this.caseObject);
    },
    changedCcEmail(val) {
      this.caseObject = {...this.caseObject, CcEmails: val};
      this.setCase(this.caseObject);
    },
    changedClient(val) {
      this.caseObject.Branch.Client = {...this.caseObject.Branch.Client, ...val};
      this.setCase(this.caseObject);
    },
    changedRequester(val) {
      this.caseObject.Branch.Requester = {...this.caseObject.Branch.Requester, ...val};
      this.setCase(this.caseObject);
    },
    changeAssignment(val) {
      this.caseObject = {...this.caseObject, ...val};
      this.setCase(this.caseObject);
    },
    changeClaimDetail(val) {
      const ScheduledApptWhen = val.Appointments[0].ScheduledApptWhen;
      const ScheduledApptWhere = val.Appointments[0].ScheduledApptWhere;
      this.caseObject = {...this.caseObject, ...val, ScheduledApptWhen, ScheduledApptWhere};
      this.setCase(this.caseObject);
    },
    changedInsured(val) {
      const InsuredText = this.caseObject.InsuredText;
      const InsuredClientID = val.InsuredID
      this.caseObject = {...this.caseObject, InsuredText, InsuredClientID};
      this.setCase(this.caseObject);
    },
    changedOperationsManager(val) {
      this.caseObject = {...this.caseObject, ...val };
      this.setCase(this.caseObject);
    },
    changeSubject(val) {
      this.caseObject.Branch.Client.Subject = {...this.caseObject.Branch.Client.Subject, ...val};
      this.setCase(this.caseObject);
    },
    changedSubjectLocation(val) {
      this.caseObject.Branch.Client.Subject = {...this.caseObject.Branch.Client.Subject, SubjectAddress: val,};
      this.setCase(this.caseObject);
    },
    changedSubjectDescription(val) {
      this.caseObject.Branch.Client.Subject = {...this.caseObject.Branch.Client.Subject, ...val};
      this.setCase(this.caseObject);
    },
    changedSurveillanceConducted(val) {
      this.caseObject.Branch.Client.Subject = {...this.caseObject.Branch.Client.Subject, ...val}
      this.setCase(this.caseObject);
    },
    editScreen() {
      this.oldReadOnlyState = this.readOnly = false;
      this.setIsReadOnly(this.readOnly);
    },
    clientEdit() {
      this.clientReadOnly = false;
      this.setClientReadOnly(this.clientReadOnly);
    },
    setUserType(val) {
      if (!val) {
        val = localStorage.getItem("userType");
      }
      this.UserType = val
    },
    uploadSubjectImage(item) {
      let data = "";
      if (this.caseObject.Branch.Client.Subject.SubjectID) {
        data = {
          ParentObjectKey: this.caseObject.Branch.Client.Subject.SubjectID,
          ParentObjectType: "Subject",
          UserTypePermissions: "User;Investigator;Client",
        }
      } else {
        this.formSubjectData = item;
        return;
      }
      let formData = new FormData();
      const documentJson = JSON.stringify(data)
      formData.append("FormFile", item.file);
      formData.append("Document", documentJson);

      apiService
          .post("file/create", formData, {'Content-Type': 'multipart/form-data'})
          .then(function (res) {
            this.$emit("reloadData");
          })
          .catch(function (response) {
            console.log(response);
          });
    },
    exportReport() {
      this.$router.push('/cases/export-report/' + this.caseObject.CaseID)
    },
    legalHoldModal() {
      this.modalShow = true;
    },
    chancelLegalHold() {
      this.modalShow = false;
    },
    changeLegalHold() {
      this.modalShow = false;
      const postData = {
        "IsLegalHold": !this.caseObject.IsLegalHold,
      };
      apiService
          .post("case/" + this.caseObject.CaseID + "/setislegalhold", postData)
          .then(res => {
            if (res) {
              this.showToast('success', 'top-center', 4000, 'Legal Hold Changed!');
              this.dataLoad(this.caseObject.CaseID, this.mainTab);
            }
          })
    },
    closeReopenCase() {
      this.$router.push('/cases/close-reopen/' + this.caseObject.CaseID)
    },
    caseVideo() {
      this.$router.push('/cases/case-video/' + this.caseObject.CaseID)
    },
    mergeCaseVideo() {
      this.$router.push('/cases/merge-case-video/' + this.caseObject.CaseID)
    },
    caseMedia() {
      this.$router.push('/cases/case-media/' + this.caseObject.CaseID)
    },
    addInvoice() {
      this.$router.push('/cases/add-invoice/' + this.caseObject.CaseID)
    },
  },
}
</script>

<style>
hr {
  border: 1px solid #ebe9f1;
}

.link {
  text-decoration: underline;
}
.accordion-title h3 {
  color: white;
  font-weight: bold;
  margin: 0.5rem;
}
.accordion-title {
  background: #476dae;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.accordion {
  margin-top: 0.2rem;
}
.collapse-arrow {
  width: .5rem;
  height: .5rem;
  margin: 0 1rem;
  border: 3px solid white;
  border-top: none;
  border-left: none;
  transform: rotate(-135deg);
  transition-duration: 200ms;
}
.collapse-arrow.open {
  transform: rotate(45deg);
}
.text-underline {
  text-decoration: underline !important;
}

/* I believe p-2 is a bootstrap class. This fixes the spacing on the cases/add page. */
.custom-p-2 {
  margin: 1.5rem 1.5rem 1.5rem 0;
}

.btn-link-box {
  border-color: #476dae !important;
}
.hold-wrapper {
  display: flex;
}
.img-hold {
  width: 2.5rem;
  background-color: #476dae;
  border-radius: 0.5rem;
  padding: 0.5rem 0.5rem 0.8rem;
  cursor: pointer;
}
.hold-btn {
  transition-duration: 1.5s;
  position: relative;
  top: 0;
  border-radius: 0.5rem;
  cursor: pointer;
  width: 2.5rem;
  height: 2.95rem;
  margin-right: 0.5rem;
}
.hover-display {
  position: relative;
  top: -2.2rem;
  padding-left: 2.3rem;
  padding-right: 0.5rem;
  opacity: 0;
}
.hold-btn:hover {
  transition-duration: 1.5s;
  background-color: #476dae;
  width: 12rem;
}
.hold-btn-open .hover-display{
  opacity: 1;
}
.hold-btn-open {
  background-color: #476dae;
  width: 12rem !important;
}
.hold-btn:hover .hover-display {
  transition-duration: 1.5s;
  border-radius: 0.5rem;
  opacity: 1;
}
.collapse-btn {
  padding: 0.5rem;
  margin-left: 1rem;
  background-color: ghostwhite !important;
}
.collapse-btn-img {
  width: 1.5rem;
  height: 1.5rem;
}
.collapse-btn-block {
  display: inline-block;
  float: right;
  margin-right: 1.5rem;
}
</style>
