<template>
  <div>
    <b-row>
      <AddressForm
        v-for="(address, index) of SubjectAddress"
        :key="index"
        :address="address"
        :index="index"
        :is-read-only="readOnly"
        @updateAddress="updateAddress"
        @deleteAddress="deleteAddress"
      />
    </b-row>
    <b-button
      type="button"
      variant="primary"
      class="mb-1 mt-1"
      :disabled="readOnly"
      @click="addAddress()"
    >
      <span>+ Add Address</span>
    </b-button>
  </div>
</template>

<script>
import AddressForm from "@/views/cases/components/AddressForm";
import {BButton, BRow} from "bootstrap-vue";
import {mapGetters} from "vuex";

export default {
  components: {
    AddressForm,
    BRow,
    BButton,
  },
  data() {
    return {
      readOnly: false,
      SubjectAddress: [],
    }
  },
  computed: {
    ...mapGetters({
      getSubject: "cases/getSubject",
      getIsReadOnly: "cases/getIsReadOnly",
    }),
    getSubjectData() {
      return this.getSubject
    },
    isReadOnly() {
      return this.getIsReadOnly;
    },
  },
  watch: {
    getSubjectData(val) {
      this.fillSubject(val);
    },
    isReadOnly(val) {
      this.readOnly = val
    },
  },
  async created() {
    this.readOnly = this.getIsReadOnly;
    try {
      this.fillSubject(this.getSubject);
    } catch (err) {
      this.error = err
    }
  },
  methods: {
    addAddress() {
      if (!this.SubjectAddress) this.SubjectAddress = [];
      const item = {
        Address1: "",
        City: "",
        StateProvince: "",
        PostalCode: "",
        Description: "",

        Address2: "",
        AddressID: "",
        AddressIndex: "",
        Country: "",
        Name: "",
        ZipCode: "",
        Subject: null,
        SubjectID: "",
      }
      this.SubjectAddress.push(item)
    },
    updateAddress(val, index) {
      this.SubjectAddress.splice(index, 1, val);
      this.$emit("changedSubjectLocation", this.SubjectAddress);
    },
    deleteAddress(index) {
      this.SubjectAddress.splice(index, 1);
      this.$emit("changedSubjectLocation", this.SubjectAddress);
    },
    fillSubject(val) {
      this.SubjectAddress = val.SubjectAddress || null;
    }
  },
}
</script>

<style scoped>

</style>