<template>
  <div class="custom-p-2">
    <div class="accordion">
      <div
        class="accordion-title"
        @click="toggleAccordion('INSTRUCTIONS')"
      >
        <div
          class="collapse-arrow"
          :class="{ open: tab.INSTRUCTIONS }"
        />
        <h3>INV/MGR Instructions</h3>
      </div>
      <b-row
        v-if="tab.INSTRUCTIONS"
        class="pt-2"
      >
        <b-col>

          <b-row v-if="getUserType === 'User'">
            <b-col>
              <b-form-group
                label="Client Special Instructions - MGR"
                label-for="clientSpecialInstructionsMGR"
              >
                <div
                  class="collapse-textarea"
                  :class="{expanded: !isExpandedClientMGR}"
                >
                  <textarea
                    v-model="ClientSpecialInstructionsMGR"
                    class="expandable-textarea"
                    :class="[isExpandedClientMGR ? 'not-expanded' : 'expanded']"
                    contentEditable
                    :readonly="true"
                  />
                  <div @click="isExpandedClientMGR = !isExpandedClientMGR">
                    <span
                      class="expand-arrow mt-n1"
                      :class="[isExpandedClientMGR ? 'not-expanded' : 'expanded']"
                    >&#187;</span>
                  </div>
                </div>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row v-if="getUserType === 'User'">
            <b-col>
              <b-form-group
                label="Branch Special Instructions - MGR"
                label-for="branchSpecialInstructionsMGR"
              >
                <div
                  class="collapse-textarea"
                  :class="{expanded: !isExpandedBranchMGR}"
                >
                  <textarea
                    v-model="BranchSpecialInstructionsMGR"
                    class="expandable-textarea"
                    :class="[isExpandedBranchMGR ? 'not-expanded' : 'expanded']"
                    contentEditable
                    :readonly="true"
                  />
                  <div @click="isExpandedBranchMGR = !isExpandedBranchMGR">
                    <span
                      class="expand-arrow mt-n1"
                      :class="[isExpandedBranchMGR ? 'not-expanded' : 'expanded']"
                    >&#187;</span>
                  </div>
                </div>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row v-if="getUserType === 'User'">
            <b-col>
              <b-form-group
                label="Requestor Special Instructions - MGR"
                label-for="requestorSpecialInstructionsMGR"
              >
                <div
                  class="collapse-textarea"
                  :class="{expanded: !isExpandedRequestorMGR}"
                >
                  <textarea
                    v-model="RequestorSpecialInstructionsMGR"
                    class="expandable-textarea"
                    :class="[isExpandedRequestorMGR ? 'not-expanded' : 'expanded']"
                    contentEditable
                    :readonly="true"
                  />
                  <div @click="isExpandedRequestorMGR = !isExpandedRequestorMGR">
                    <span
                      class="expand-arrow mt-n1"
                      :class="[isExpandedRequestorMGR ? 'not-expanded' : 'expanded']"
                    >&#187;</span>
                  </div>
                </div>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col>
              <b-form-group
                label="Client Special Instructions - INV"
                label-for="clientSpecialInstructionsINV"
              >
                <div
                  class="collapse-textarea"
                  :class="{expanded: !isExpandedClientINV}"
                >
                  <textarea
                    v-model="ClientSpecialInstructionsINV"
                    class="expandable-textarea"
                    :class="[isExpandedClientINV ? 'not-expanded' : 'expanded']"
                    contentEditable
                    :readonly="true"
                  />
                  <div @click="isExpandedClientINV = !isExpandedClientINV">
                    <span
                      class="expand-arrow mt-n1"
                      :class="[isExpandedClientINV ? 'not-expanded' : 'expanded']"
                    >&#187;</span>
                  </div>
                </div>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col>
              <b-form-group
                label="Branch Special Instructions - INV"
                label-for="branchSpecialInstructionsINV"
              >
                <div
                  class="collapse-textarea"
                  :class="{expanded: !isExpandedBranchINV}"
                >
                  <textarea
                    v-model="BranchSpecialInstructionsINV"
                    class="expandable-textarea"
                    :class="[isExpandedBranchINV ? 'not-expanded' : 'expanded']"
                    contentEditable
                    :readonly="true"
                  />
                  <div @click="isExpandedBranchINV = !isExpandedBranchINV">
                    <span
                      class="expand-arrow mt-n1"
                      :class="[isExpandedBranchINV ? 'not-expanded' : 'expanded']"
                    >&#187;</span>
                  </div>
                </div>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group
                label="Requestor Special Instructions - INV"
                label-for="requestorSpecialInstructionsINV"
              >
                <div
                  class="collapse-textarea"
                  :class="{expanded: !isExpandedRequestorINV}"
                >
                  <textarea
                    v-model="RequestorSpecialInstructionsINV"
                    class="expandable-textarea"
                    :class="[isExpandedRequestorINV ? 'not-expanded' : 'expanded']"
                    contentEditable
                    :readonly="true"
                  />
                  <div @click="isExpandedRequestorINV = !isExpandedRequestorINV">
                    <span
                      class="expand-arrow mt-n1"
                      :class="[isExpandedRequestorINV ? 'not-expanded' : 'expanded']"
                    >&#187;</span>
                  </div>
                </div>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group
                label="Notes Regarding This Assignment"
                label-for="notesRegardingThisAssignment"
              >
                <div
                  class="collapse-textarea"
                  :class="{expanded: !isExpandedNotesRegarding}"
                >
                  <textarea
                    id="NotesRegarding"
                    v-model="NotesRegardingThisAssignment"
                    class="expandable-textarea"
                    :class="[isExpandedNotesRegarding ? 'not-expanded' : 'expanded']"
                    contentEditable
                    :readonly="true"
                  />
                  <div @click="isExpandedNotesRegarding = !isExpandedNotesRegarding">
                    <span
                      class="expand-arrow mt-n1"
                      :class="[isExpandedNotesRegarding ? 'not-expanded' : 'expanded']"
                    >&#187;</span>
                  </div>
                </div>
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import { BCol, BFormGroup, BRow} from "bootstrap-vue";
import {mapGetters} from "vuex";
import APIService from "@core/utils/APIService";

const apiService = new APIService();

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
  },
  data() {
    return {
      ClientSpecialInstructionsMGR: "",
      BranchSpecialInstructionsMGR: "",
      RequestorSpecialInstructionsMGR: "",
      ClientSpecialInstructionsINV: "",
      BranchSpecialInstructionsINV: "",
      RequestorSpecialInstructionsINV: "",
      NotesRegardingThisAssignment: "",

      isExpandedClientMGR: false,
      isExpandedBranchMGR: false,
      isExpandedRequestorMGR: false,
      isExpandedClientINV: false,
      isExpandedBranchINV: false,
      isExpandedRequestorINV: false,
      isExpandedNotesRegarding: false,

      tab: {
        INSTRUCTIONS: true,
      },

      readOnly: true,
    }
  },
  computed: {
    ...mapGetters({
      getCase: "cases/getCase",
      getUserType: "auth/getUserType",
      getSubject: "cases/getSubject",
      getIsReadOnly: "cases/getIsReadOnly",
    }),
  },
  watch: {
    NotesRegardingThisAssignment(val) {
      if (val) {
        const hasMultipleLines = val.includes('\n');
        if (hasMultipleLines) this.isExpandedNotesRegarding = hasMultipleLines
        else this.checkText()
      }
    }
  },
  async created() {
    try {
      this.NotesRegardingThisAssignment = this.getSubject.NotesRegardingThisAssignment || null;
      apiService
          .get("client/" + this.getCase.Branch.Client.ClientID + "/detail")
          .then((response) => {
            this.ClientSpecialInstructionsMGR = response.data.SpecialInstructionsManager;
            this.ClientSpecialInstructionsINV = response.data.SpecialInstructionsInvestigator;
          })
      apiService
          .get("client/branch/" + this.getCase.Branch.BranchID + "/detail")
          .then((response) => {
            this.BranchSpecialInstructionsMGR = response.data.SpecialInstructionsManager;
            this.BranchSpecialInstructionsINV = response.data.SpecialInstructionsInvestigator;
          });
      apiService
          .get("client/requestor/" + this.getCase.Branch.Requester.UserAccountID + "/detail")
          .then((response) => {
            this.RequestorSpecialInstructionsMGR = response.data.SpecialInstructionsManager;
            this.RequestorSpecialInstructionsINV = response.data.SpecialInstructionsInvestigator;
          });
    } catch (err) {
      console.log(err)
    }
  },
  methods: {
    toggleAccordion(tab) {
      this.tab[tab] = !this.tab[tab];
    },
    checkText() {
      const textarea = document.getElementById('NotesRegarding');
      const text = textarea.value;

      const tempDiv = document.createElement('div');
      tempDiv.style.position = 'absolute';
      tempDiv.style.visibility = 'hidden';
      tempDiv.style.whiteSpace = 'pre-wrap';
      tempDiv.style.wordWrap = 'break-word';
      tempDiv.style.width = textarea.clientWidth + 'px';
      tempDiv.style.fontFamily = window.getComputedStyle(textarea).fontFamily;
      tempDiv.style.fontSize = window.getComputedStyle(textarea).fontSize;
      tempDiv.style.lineHeight = window.getComputedStyle(textarea).lineHeight;
      tempDiv.textContent = text;

      document.body.appendChild(tempDiv);

      const lineHeight = parseInt(window.getComputedStyle(tempDiv).lineHeight);
      const tempDivHeight = tempDiv.clientHeight;
      const numberOfLines = Math.round(tempDivHeight / lineHeight);

      document.body.removeChild(tempDiv);

      this.isExpandedNotesRegarding = numberOfLines > 1;

    }
  } 
}
</script>

<style scoped>

</style>