<template>
  <div class="uploader-wrapper">
    <VueFileAgent
      v-model="fileRecords"
      class="m-2"
      :multiple="true"
      :disabled="inProgress"
    />
    <FileUploadRow
      v-for="(file, index) in fileRecords"
      :key="index"
      :file="file"
      :index="index"
      class="pl-2 font-small-3"
      @removeFile="remove"
      @changeData="changeData"
      @playHandler="playHandler"
      @pauseHandler="pauseHandler"
      @stopHandler="stopHandler"
    />
    <b-row class="pr-3 pl-4 pb-2 mt-2">
      <b-col>
        <div class="d-flex justify-content-between">
          <b-button
            v-if="!inProgress || uploadFinished"
            type="reset"
            variant="outline-secondary"
            @click="resetForm()"
          >
            {{ uploadFinished ? "Close" : "Cancel" }}
          </b-button>

          <b-button
            v-if="!inProgress"
            type="submit"
            :variant="fileRecords.length ? 'primary' : 'outline-secondary'"
            class="mr-1"
            :disabled="!fileRecords.length"
            @click.prevent="uploadFiles()"
          >
            Upload Files
          </b-button>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {BButton, BCol, BRow} from "bootstrap-vue";
import FileUploadRow from "@/views/cases/components/FileUploadRow";

export default {
  components: {
    FileUploadRow,
    BRow,
    BCol,
    BButton,
  },
  data() {
    return {
      fileRecords: [],
      Files: [],
      inProgress: false,
      uploadFinished: false
    }
  },
  watch: {
    fileRecords(val) {
      val.map(f => {
        let duplicat = false;
        const item = {
          file: f,
          data: "",
        }
        this.Files.map(i => {
          if (i.file.file.name === f.file.name && !duplicat) {
            duplicat = true
          }
        })
        if (!duplicat) this.Files.push(item);
        return this.Files;
      })
    },
  },
  created: function() {
    this.$parent.$on('updateFile', (index, field, state) => this.changeFileState(index, field, state));
    this.$parent.$on('uploadFinished', () => this.uploadFinished = true);
  },
  methods: {
    remove(i) {
      this.Files.splice(i, 1);
      this.fileRecords.splice(i, 1);
    },

    uploadFiles() {
      this.inProgress = true;
      this.$emit("uploadFiles", this.Files);
    },

    resetForm() {
      this.fileRecords = [];
      this.$emit("cancelUploadFiles");
    },

    changeData(i, data) {
      const item = {
        file: this.Files[i].file,
        data,
      }
      this.Files.splice(i, 1, item)
    },

    playHandler(index) {
      this.changeFileState(index, "isPaused", false);
      this.$emit("playHandler", index);
    },

    pauseHandler(index) {
      this.changeFileState(index, "isPaused", true);
    },

    stopHandler(index) {
      this.changeFileState(index, "abortFile", true);
    },

    changeFileState(index, field, state) {
      let oldFile = Object.assign({}, this.Files[index]);
      let oldRecord = Object.assign({}, this.fileRecords[index]);

      oldFile.file = Object.assign(oldFile.file, {[field]: state});
      oldRecord.file = Object.assign(oldRecord.file, {[field]: state});

      this.Files.splice(index, 1, oldFile)
      this.fileRecords.splice(index, 1, oldRecord)
    }
  },
}
</script>

<style scoped>
.uploader-wrapper {
  position: absolute;
  width: 90%;
  min-height: 25rem;
  background: white;
  border: 2px solid #ebe9f1;
  border-radius: 5px;
  top: 8rem;
  left: 50%;
  transform: translate(-50%, 0);
  box-shadow: 0 5px 4px 1px #ebe9f1;
  z-index: 1;
}
</style>