<template>
  <div class="drag-block">
    <VueFileAgent
      v-model="SubjectImage"
      :disabled="isReadOnly"
      :multiple="false"
    />
    <div
      v-if="uploadUrl"
      class="upload-img"
    >
      <img
        id="image"
        :src="uploadUrl"
      >
    </div>
  </div>
</template>

<script>

import {mapGetters, mapMutations} from "vuex";
import APIService from "@core/utils/APIService";
import ToastificationContent from "@core/components/toastification/ToastificationContent";

const apiService = new APIService();

export default {
  props: {
    isReadOnly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      SubjectImage: null,
      SubjectID: "",
      uploadUrl: "",
    }
  },
  computed: {
    ...mapGetters({
      getSubject: "cases/getSubject",
      getCase: "cases/getCase",
    }),
    getSubjectData() {
      return this.getSubject
    }
  },
  watch: {
    getSubjectData(val) {
      this.fillSubjectData(val);
    },
    SubjectImage(val) {
      this.uploadUrl = window.URL.createObjectURL(val.file);
      if (val.file) {
        if (this.uploadUrl) {
          const patchArr = this.uploadUrl.split("/");
          const removeID = patchArr[patchArr.length - 1];
          apiService
              .delete("file/" + removeID)
              .then(res => {
                this.uploadFiles(val);
                this.setUploadUrl(this.uploadUrl);
              })
              .catch(() => {
                this.uploadFiles(val);
                this.setUploadUrl(this.uploadUrl);
              })
        } else {
          this.uploadFiles(val);
          this.setUploadUrl(this.uploadUrl);
        }
      } else if (this.SubjectImage !== null) {
        setTimeout(() => {
          this.SubjectImage = null;
        }, 100);
        if (this.SubjectImage !== null) this.showToast('danger', 'top-center', 4000, JSON.stringify('Couldn\'t upload image'))
      }
    }
  },
  created() {
    if (this.getSubject) this.SubjectID = this.getSubject.SubjectID;
  },
  mounted() {
    this.fillSubjectData(this.getSubject);
    setTimeout(() => {
      const Image = document.querySelector("#image")
      if (Image !== null) {
        Image.addEventListener('error', (event) => {
          console.log(event)
        });
      }
    }, 1000)
  },
  methods: {
    ...mapMutations({
      setUploadUrl: "cases/setUploadUrl"
    }),
    showToast(variant, position, timeout, data) {
      this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Notification',
              icon: 'InfoIcon',
              text: data,
              variant,
            },
          },
          {
            position,
            timeout,
          })
    },
    fillSubjectData(val) {
      this.SubjectID = val.SubjectID;
      this.uploadUrl = val.Image;
      this.setUploadUrl(this.uploadUrl);
    },
    uploadFiles(item) {
      this.$emit("uploadSubjectImage", item);
    },
  },
}
</script>

<style scoped>
.drag-block {
  position: relative;
  border: 1px solid grey;
  margin: 1rem 0;
  padding: 0.2rem;
}
.upload-img {
  display: flex;
  position: absolute;
  top: 0;
  background-color: ghostwhite;
  width: 98%;
  height: 100%;
  z-index: 1;
}
</style>