<template>
  <b-col
    md="6"
    class="mt-2"
  >
    <b-form-group
      :label="'Address ' + (index + 1)"
      :label-for="'address' + index"
    >
      <span
        class="maps-link font-small-3"
        @click="viewInGoogleMaps()"
      >View Address in Google Maps</span>
      <div
        :id="'address' + index"
        class="white-bg-container border-gray width-500"
      >
        <b-row>
          <b-col>
            <validation-provider
              #default="{ errors }"
              name="Street Address"
              rules="required"
            >
              <b-form-group
                class="required-data"
                :state="errors && errors.length > 0 ? false : null"
                label="Street Address"
                label-for="streetAddress"
              >
                <b-form-input
                  id="streetAddress"
                  v-model="Address1"
                  placeholder=""
                  name="streetAddress"
                  :disabled="isReadOnly || (firstReadOnly && index === 0)"
                />
                <small
                  class="text-danger"
                  :state="errors && errors.length > 0 ? false : null"
                >
                  {{ errors[0] }}
                </small>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="5">
            <validation-provider
              #default="{ errors }"
              name="City"
              rules="required"
            >
              <b-form-group
                class="required-data"
                :state="errors && errors.length > 0 ? false : null"
                label="City"
                label-for="city"
              >
                <b-form-input
                  id="city"
                  v-model="City"
                  placeholder=""
                  name="city"
                  :disabled="isReadOnly || (firstReadOnly && index === 0)"
                />
                <small
                  class="text-danger"
                  :state="errors && errors.length > 0 ? false : null"
                >
                  {{ errors[0] }}
                </small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col md="4">
            <validation-provider
              #default="{ errors }"
              name="State"
              rules="required"
            >
              <b-form-group
                class="required-data"
                :state="errors && errors.length > 0 ? false : null"
                label="State"
                label-for="state"
              >
                <v-select
                  id="state"
                  v-model="StateProvince"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="title"
                  :options="StatesList"
                  placeholder=""
                  name="state"
                  :disabled="isReadOnly || (firstReadOnly && index === 0)"
                />
                <small
                  class="text-danger"
                  :state="errors && errors.length > 0 ? false : null"
                >
                  {{ errors[0] }}
                </small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col>
            <validation-provider
              #default="{ errors }"
              name="Zip"
              rules="required"
            >
              <b-form-group
                class="required-data"
                :state="errors && errors.length > 0 ? false : null"
                label="Zip"
                label-for="zip"
              >
                <ZipInput
                  id="zip"
                  v-model="PostalCode"
                  :disabled="isReadOnly || (firstReadOnly && index === 0)"
                />
                <small
                  class="text-danger"
                  :state="errors && errors.length > 0 ? false : null"
                >
                  {{ errors[0] }}
                </small>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group
              label="Description"
              label-for="description"
            >
              <div
                class="collapse-textarea"
                :class="{expanded: !isExpanded}"
              >
                <textarea
                  v-model="Description"
                  class="expandable-textarea"
                  :class="[isExpanded ? 'not-expanded' : 'expanded']"
                  contentEditable
                  :disabled="isReadOnly || (firstReadOnly && index === 0)"
                />
                <div @click="isExpanded = !isExpanded">
                  <span
                    class="expand-arrow mt-n1"
                    :class="[isExpanded ? 'not-expanded' : 'expanded']"
                  >&#187;</span>
                </div>
              </div>
            </b-form-group>
          </b-col>
        </b-row>
        <div
          v-if="index !== 0"
          class="text-right"
        >
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="reset"
            variant="outline-danger"
            :disabled="isReadOnly"
            @click="deleteAddress()"
          >
            Delete
          </b-button>
        </div>
      </div>
    </b-form-group>
  </b-col>
</template>

<script>
import {BButton, BCol, BFormGroup, BFormInput, BRow} from "bootstrap-vue";
import vSelect from "vue-select";
import { mapGetters } from "vuex";
import { ValidationProvider } from "vee-validate";
import Ripple from "vue-ripple-directive";
import ZipInput from "@core/components/zipInput/ZipInput.vue";

export default {
  components: {
    ZipInput,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    vSelect,
    ValidationProvider,
    BButton,
  },
  directives: {
    Ripple,
  },
  props: {
    address: {
      type: Object,
      default: null,
    },
    index: {
      type: Number,
      default: 0,
    },
    isReadOnly: {
      type: Boolean,
      default: false,
    },
    firstReadOnly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      CurrentAddress: {},
      Address1: "",
      City: "",
      StateProvince: "",
      ZipCode: "",
      Description: "",

      Address2: "",
      AddressID: "",
      AddressIndex: "",
      Country: "",
      Name: "",
      PostalCode: "",
      Subject: null,
      SubjectID: "",
      isExpanded: false,

      StatesList: [
        'AK', 'AL', 'AR', 'AZ', 'CA', 'CO', 'CT', 'DC', 'DE', 'FL', 'GA',
        'HI', 'IA', 'ID', 'IL', 'IN', 'KS', 'KY', 'LA', 'MA', 'MD', 'ME',
        'MI', 'MN', 'MO', 'MS', 'MT', 'NC', 'ND', 'NE', 'NH', 'NJ', 'NM',
        'NV', 'NY', 'OH', 'OK', 'OR', 'PA', 'RI', 'SC', 'SD', 'TN', 'TX',
        'UT', 'VA', 'VT', 'WA', 'WI', 'WV', 'WY'
      ],
    }
  },
  computed: {
    ...mapGetters({
      getSubject: "cases/getSubject",
      getAllSubjectTabOpen: "cases/getAllSubjectTabOpen",
    }),
    getSubjectData() {
      return this.getSubject;
    },
  },
  watch: {
    getAllSubjectTabOpen: {
      immediate: true,
      handler(value) {
        const val = !!value
        this.isExpanded = val
      }
    },
    getSubjectData(val) {
      this.fillAddress(val);
    },
    Address1(val) {this.CurrentAddress = {...this.CurrentAddress, Address1: val}},
    City(val) {this.CurrentAddress = {...this.CurrentAddress, City: val}},
    StateProvince(val) {this.CurrentAddress = {...this.CurrentAddress, StateProvince: val}},
    Description(val) {this.CurrentAddress = {...this.CurrentAddress, Description: val}},
    PostalCode(val) {this.CurrentAddress = {...this.CurrentAddress, PostalCode: val}},

    Address2(val) {this.CurrentAddress = {...this.CurrentAddress, Address2: val}},
    AddressID(val) {this.CurrentAddress = {...this.CurrentAddress, AddressID: val}},
    AddressIndex(val) {this.CurrentAddress = {...this.CurrentAddress, AddressIndex: val}},
    Country(val) {this.CurrentAddress = {...this.CurrentAddress, Country: val}},
    Name(val) {this.CurrentAddress = {...this.CurrentAddress, Name: val}},
    ZipCode(val) {this.CurrentAddress = {...this.CurrentAddress, ZipCode: val}},
    Subject(val) {this.CurrentAddress = {...this.CurrentAddress, Subject: val}},
    SubjectID(val) {this.CurrentAddress = {...this.CurrentAddress, SubjectID: val}},

    CurrentAddress(val) {
      this.$emit("updateAddress", val, this.index);
    },
    address(val) {
      this.CurrentAddress = val
    },
  },
  async created() {
    try {
      this.fillAddress(this.getSubject);
    } catch (err) {
      this.error = err;
    }
  },
  methods: {
    fillAddress(val) {
      const item = val.SubjectAddress ? val.SubjectAddress[this.index] : val.Addresses[this.index];
      this.Address1 = item.Address1;
      this.City = item.City;
      this.StateProvince = item.StateProvince;
      this.Description = item.Description;
      this.PostalCode = item.PostalCode;

      this.Address2 = item.Address2;
      this.AddressID = item.AddressID;
      this.AddressIndex = item.AddressIndex;
      this.Country = item.Country;
      this.Name = item.Name;
      this.ZipCode = item.ZipCode;
      this.Subject = item.Subject;
      this.SubjectID = item.SubjectID;
    },
    viewInGoogleMaps() {
      let string = `${this.Address1 && `${this.Address1},`}${
        this.City && `${this.City},`
      }${this.StateProvince && `${this.StateProvince},`}${
        this.PostalCode && `${this.PostalCode}`
      }`;
      string
        ? (string = string.replace(/  +/g, " ").split(/[ ,]+/).join("+"))
        : "";
      window.open(`https://www.google.com.ua/maps/place/${string}`);
    },
    deleteAddress() {
      this.$emit("deleteAddress", this.index);
    }
  },
}
</script>

<style scoped>
.maps-link {
  position: absolute;
  top: 2rem;
  left: 330px;
  text-decoration: underline;
  cursor: pointer;
  z-index: 1;
  white-space: nowrap;
}

.border-gray {
  border: 1px solid #c7c7cc !important
}
</style>