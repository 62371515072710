<template>
  <div class="d-flex">
    <div
      v-if="!onLine"
      class="mr-2"
    >
      <feather-icon
        icon="CloudOffIcon"
        size="21"
        class="navbar-icon-blue"
      />
    </div>
    <div v-else class="mr-2"/>
    <div
      v-if="!isEmptyDB"
      class="mr-2"
    >
      <feather-icon
        icon="DatabaseIcon"
        size="21"
        class="navbar-icon-blue"
      />
    </div>
    <div v-else class="mr-2"/>
  </div>
</template>

<script>

import ToastificationContent from "@core/components/toastification/ToastificationContent";
import {cacheForOffline, checkDatabase, clearCache} from "@core/utils/offlineService";
import {mapGetters, mapMutations} from "vuex";

export default {
  data() {
    return {
      onLine: navigator.onLine,
      dbPromise: null,
      isEmptyDB: true,
    }
  },
  computed: {
    ...mapGetters({
      getOnlineStatus: "offline/getOnlineStatus",
    })
  },
  watch: {
    getOnlineStatus(val) {
      this.onLine = val;
    },

    onLine(v, oldV) {
      if (v) {
        if (v !== oldV) {
          this.$oidc.isLoggedIn().then(res => {
            if (!res) {
              this.$oidc.logout()
            }
          })
          if (this.isEmptyDB) {
            this.cacheDataForOffline()
          }
        }
      }
    },

    isEmptyDB(val, oldVal) {
      if (val && !oldVal) {
        this.showToast("success", "top-center", 4000, "All offline activity has been synchronized.");
        this.cacheDataForOffline()
      }
    },
  },
  mounted() {
    window.addEventListener('online', this.setOnlineStatus);
    window.addEventListener('offline', this.setOnlineStatus);
    setInterval(() => {
      checkDatabase()
          .then(hasDatabase => {
            this.isEmptyDB = !hasDatabase;
          })
          .catch(error => {
            console.error('Error', error);
          });
    }, 5000)
  },
  beforeDestroy() {
    window.removeEventListener('online', this.setOnlineStatus);
    window.removeEventListener('offline', this.setOnlineStatus);
  },
  methods: {
    ...mapMutations({
      setOnlineStatus: "offline/setOnlineStatus"
    }),
    showToast(variant, position, timeout, data) {
      this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: "Notification",
              icon: "InfoIcon",
              text: data,
              variant,
            },
          },
          {
            position,
            timeout,
          }
      );
    },

    async cacheDataForOffline() {
      await clearCache();
      const rowsForCache = sessionStorage.getItem("rowsForCache")
      if (rowsForCache) {
        await cacheForOffline(JSON.parse(rowsForCache), (result, errors) => {
          if (errors) {
            this.showToast("warning", "top-center", 4000, "Some files were unavailable to sync and are not available.");
          }
        })
      }
    },
  },
}
</script>

<style scoped>

</style>@/@core/utils/offlineService