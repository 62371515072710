<template>
  <div class="case-file white-bg-container">
    <b-form-group
      label-size="lg"
      label="Case File Library"
      label-for="caseFileLibrary"
    >
      <b-row id="caseFileLibrary">
        <b-col md="6">
          <b-button
            type="button"
            :variant="btnVariant('CURRENT_CASE')"
            class="mr-1"
            @click.prevent="openMainTab('CURRENT_CASE')"
          >
            Current Case
          </b-button>
          <b-button
            v-if="getCurrentScopes.indexOf('st2.viewrelatedcases') !== -1"
            type="button"
            :variant="btnVariant('RELATED_CASES')"
            class="mr-1"
            @click.prevent="openMainTab('RELATED_CASES')"
          >
            Related Cases
          </b-button>
        </b-col>
        <b-col
          md="1"
          class="mr-1 pt-05 text-nowrap d-flex align-items-center"
        >
          <p
            v-if="mainTab === 'CURRENT_CASE' && onLine"
            class="cursor-pointer"
            @click="addFiles"
          >Add Files
            <img
              src="../../../assets/images/logo/download.png"
              alt="download"
            >
          </p>
        </b-col>
        <b-col>
          <b-form-input
            id="filterFiles"
            v-model="FilterFiles"
            placeholder="Filter Files"
            name="filterFiles"
          />
        </b-col>
      </b-row>

      <b-row
        v-if="mainTab === 'CURRENT_CASE'"
        class="pr-1 pl-1"
      >
        <b-col class="border-top-dark min-height-10 rounded-sm">
          <b-row
            class="bg-light border-left-dark border-right-dark height-50 d-flex align-items-center"
            :class="{'border-bottom-dark': !Files.length}"
          >
            <b-col md="5">
              File Name
            </b-col>
            <b-col md="1">
              Size
            </b-col>
            <b-col md="2">
              Date
            </b-col>
            <b-col md="3">
              View
            </b-col>
            <b-col md="1">
              Actions
            </b-col>
          </b-row>
          <b-row
            v-for="(file, index) of Files"
            :key="index"
            class="border-left-dark border-right-dark d-flex align-items-center"
            :class="{'border-bottom-dark': index + 1 === Files.length}"
          >
            <b-col md="5">
              {{ file.FileName }}
            </b-col>
            <b-col md="1">
              {{ file.FileSize | SizeFormat }}
            </b-col>
            <b-col md="2">
              {{ file.CreatedAt | dateFormat }}
            </b-col>
            <b-col md="3">
              {{ file.UserTypePermissions | PermissionFormat }}
            </b-col>
            <b-col md="1">
              <span>
                <b-dropdown
                  variant="link"
                  toggle-class="text-decoration-none"
                  no-caret
                >
                  <template v-slot:button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="18"
                      class="text-body align-top p-0"
                    />
                  </template>
                  <b-dropdown-item @click="downloadFile(file)">
                    <feather-icon
                      icon="DownloadIcon"
                      class="mr-50"
                    />
                    <span>
                      {{ file.FileName }}
                    </span>
                  </b-dropdown-item>
                  <b-dropdown-item
                    v-if="currentCase.Status !== 'Closed' && !currentCase.IsLegalHold && onLine"
                    @click="showModal(file)"
                  >
                    <feather-icon
                      icon="TrashIcon"
                      class="mr-50"
                    />
                    <span>Delete</span>
                  </b-dropdown-item>
                </b-dropdown>
              </span>
            </b-col>
          </b-row>
        </b-col>
      </b-row>

      <b-row
        v-if="mainTab === 'RELATED_CASES'"
        class="p-1"
      >
        <b-col class="border-top-dark min-height-10 rounded-sm">
          <b-row
            class="bg-light border-left-dark border-right-dark height-50 d-flex align-items-center"
            :class="{'border-bottom-dark': !RelatedFiles.length}"
          >
            <b-col md="2">
              Internal Number
            </b-col>
            <b-col md="3">
              File Name
            </b-col>
            <b-col md="1">
              Size
            </b-col>
            <b-col md="2">
              Date
            </b-col>
            <b-col md="3">
              View
            </b-col>
            <b-col>Actions</b-col>
          </b-row>
          <b-row
            v-for="(file, index) of FilteredRelatedFiles"
            :key="index"
            class="border-left-dark border-right-dark d-flex align-items-center"
            :class="{'border-bottom-dark': index + 1 === FilteredRelatedFiles.length}"
          >
            <b-col md="2">
              <router-link :to="'/case/' + file.CaseID + '/detail'">
                {{ file.CaseFileNumber }}
              </router-link>
            </b-col>
            <b-col
              md="3"
              class="text-truncate"
            >
              {{ file.FileName }}
            </b-col>
            <b-col md="1">
              {{ file.FileSize | SizeFormat }}
            </b-col>
            <b-col md="2">
              {{ file.CreatedAt | dateFormat }}
            </b-col>
            <b-col md="3">
              {{ file.UserTypePermissions | PermissionFormat }}
            </b-col>
            <b-col md="1">
              <span>
                <b-dropdown
                  variant="link"
                  toggle-class="text-decoration-none"
                  no-caret
                >
                  <template v-slot:button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="18"
                      class="text-body align-top p-0"
                    />
                  </template>
                  <b-dropdown-item @click="downloadFile(file)">
                    <feather-icon
                      icon="DownloadIcon"
                      class="mr-50"
                    />
                    <span>
                      {{ file.FileName }}
                    </span>
                  </b-dropdown-item>
                  <b-dropdown-item
                    v-if="!currentCase.ClosedByUserID"
                    @click="deleteFile(file)"
                  >
                    <feather-icon
                      icon="TrashIcon"
                      class="mr-50"
                    />
                    <span>Delete</span>
                  </b-dropdown-item>
                </b-dropdown>
              </span>
            </b-col>
          </b-row>
        </b-col>
      </b-row>

    </b-form-group>
    <FilesUploader
      v-if="filesUploader"
      @uploadFiles="uploadFiles"
      @cancelUploadFiles="cancelUploadFiles"
      @playHandler="playHandler"
    />

    <b-modal
      v-model="modalShow"
      hide-backdrop
      size="md"
      centered
    >
      <template #modal-header="">
        <div class="d-flex mt-1 mb-n3 text-center flex-column justify-content-center">
          <h2 class="text-center">
            <strong>Delete File </strong>
          </h2>
          <h3 class="text-center">
            Are you sure you you want to delete file: {{ deletedFile.FileName }}
          </h3>
        </div>
      </template>

      <template #modal-footer="">
        <div class="d-flex mb-1 justify-content-center flex-grow-1">
          <div class="w-50 d-flex justify-content-center">
            <b-button
              type="reset"
              variant="outline-secondary"
              size="md"
              @click="hideModal()"
            >
              Cancel
            </b-button>
          </div>
          <div class="w-50 d-flex justify-content-center">
            <b-button
              type="submit"
              variant="primary"
              class="mr-1"
              size="md"
              @click="deleteFile()"
            >
              Delete
            </b-button>
          </div>
        </div>
      </template>
    </b-modal>

  </div>
</template>

<script>
import {BButton, BCol, BDropdown, BDropdownItem, BFormGroup, BFormInput, BRow} from "bootstrap-vue";
import FilesUploader from "@/views/cases/components/FilesUploader";
import APIService from "@core/utils/APIService";
import {mapGetters, mapMutations} from "vuex";
import ToastificationContent from "@core/components/toastification/ToastificationContent";

const apiService = new APIService();


export default {
  components: {
    FilesUploader,
    BButton,
    BFormGroup,
    BRow,
    BCol,
    BFormInput,
    BDropdown,
    BDropdownItem,
  },
  filters: {
    dateFormat(val) {
      const date = new Date(val);
      const options = {year: 'numeric', month: '2-digit', day: '2-digit'}
      if (date) return date.toLocaleDateString("en-US", options);
    },
    PermissionFormat(val) {
      if (val) {
        var temp = val.replaceAll(";", ", ");
        if (temp.slice(temp.length-2,temp.length) == ', ')
          temp = temp.slice(0,temp.length-2)
        return temp;
      }
    },
    SizeFormat(bytes) {
      if (!+bytes) return '0 Bytes'
      const k = 1024
      const sizes = ['Bt', 'Kb', 'Mb', 'Gb', 'Tb']
      const i = Math.floor(Math.log(bytes) / Math.log(k))
      return `${parseFloat((bytes / Math.pow(k, i)).toFixed(0))} ${sizes[i]}`
    },
  },
  data() {
    return {
      currentCase: {},
      mainTab: "CURRENT_CASE",
      FilterFiles: "",
      filesUploader: false,
      Files: [],
      AllFiles: [],
      UploadFiles: [],
      RelatedFiles: [],
      FilteredRelatedFiles: [],
      isLoadingRelatedFiles: true,
      deletedFile: null,

      UserType: "",
      readOnly: false,
      modalShow: false,

      onLine: navigator.onLine,
    }
  },
  computed: {
    ...mapGetters({
      getCase: "cases/getCase",
      getIsReadOnly: "cases/getIsReadOnly",
      getCurrentScopes: "scopes/getCurrentScopes",
      getOnlineStatus: "offline/getOnlineStatus",
    }),
    getCaseData() {
      return this.getCase
    },
    isReadOnly() {
      return this.getIsReadOnly;
    },
  },
  watch: {
    getOnlineStatus(val) {
      this.onLine = val;
    },
    getCaseData(val) {
      this.currentCase = val;
    },
    isReadOnly(val) {
      this.readOnly = val
    },
    FilterFiles(val) {
      this.Files = this.AllFiles.filter(i => i.FileName.toLowerCase().indexOf(val.toLowerCase()) !== -1);
      this.FilteredRelatedFiles = this.getFilteredRelatedFiles(val);
    }
  },
  async created() {
    this.readOnly = this.getIsReadOnly;
    this.UserType = localStorage.getItem("userType")
    try {
      this.currentCase = this.getCase;
      this.refreshFileData();
    } catch (err) {
      this.error = err
    }
  },
  methods: {
    ...mapMutations({
      setCase: "cases/setCase",
    }),
    showToast(variant, position, timeout, data) {
      this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Notification',
              icon: 'InfoIcon',
              text: data,
              variant,
            },
          },
          {
            position,
            timeout,
          })
    },

    showModal(file) {
      this.modalShow = true;
      this.deletedFile = file;
    },

    hideModal() {
      this.modalShow = false;
    },

    openMainTab(tab) {
      this.mainTab = tab
    },

    btnVariant(tab) {
      return this.mainTab === tab ? "primary" : "none"
    },

    getFilteredRelatedFiles(filterVal) {
      return this.RelatedFiles.filter(i => i.FileName.toLowerCase().indexOf(filterVal.toLowerCase()) !== -1);
    },

    deleteFile() {
      apiService
          .delete("file/" + this.deletedFile.FileID)
          .then(() => {
            this.AllFiles = this.Files = this.Files.filter(i => i.FileID !== this.deletedFile.FileID);
            this.hideModal();
          });
    },

    addFiles() {
      this.filesUploader = true;
    },

    uploadFiles(data) {
      this.UploadFiles = data.map(item => {
        return {
          FileName: item.file.file.name,
          FileSize: item.file.file.size,
          CreatedAt: item.file.file.lastModified,
          View: "",
          file: item.file.file,
          data: item.data,
        };
      })
      if (this.getCase.CaseID) {
        this.UploadFiles.map(async (FileData, index) => {
          if (FileData.FileSize > (1024 * 1024 * 5)) {
            await this.uploadLargeFile(FileData, index)
          } else {
            await this.uploadSmallFile(FileData, index)
          }
        })
      } else {
        this.$emit("uploadFinished")
        this.showToast('danger', 'top-center', 4000, JSON.stringify("You don't have Case id"));
      }
    },

    async uploadSmallFile(FileData, index) {
      const _this = this;
      _this.$emit("updateFile", index, "inProgress", true)
      let formData = new FormData();
      const data = {
        ParentObjectKey: this.getCase.CaseID,
        ParentObjectType: "Case",
        UserTypePermissions: FileData.data,
      }
      const documentJson = JSON.stringify(data)
      formData.append("FormFile", FileData.file);
      formData.append("Document", documentJson);

      return apiService
          .post("file/create", formData, {'Content-Type': 'multipart/form-data'})
          .then(function () {
            _this.$emit("updateFile", index, "inProgress", false)
            _this.$emit("updateFile", index, "progressBar", 100)
            if ((_this.UploadFiles.length - 1) === index) _this.$emit("uploadFinished")
            _this.refreshFileData();
          })
          .catch(function (response) {
            console.log(response);
          });
    },

    async uploadLargeFile(FileData, index) {
      this.$emit("updateFile", index, "inProgress", true)
      await this.uploadVideoChunks(FileData, index)
    },

    async uploadVideoChunks(item, index) {
      const _this = this;
      const chunkSize = 1024 * 1024 * 5; // (5 MB)
      let currentChunk = item.currentChunk || 0;
      let progressBar = 0;
      let prevETags = item.prevETags || null;

      const file = item.file
      const totalChunks = Math.ceil(file.size / chunkSize);

      if (!item.key && !item.uploadId) {
        const res = await _this.generateUploadId(item)
        if (!res || !res.data) return
        const {key, uploadId} = res.data;

        Object.assign(_this.UploadFiles[index], {key, uploadId, prevETags, currentChunk, totalChunks})
      }

      while ((currentChunk < totalChunks + 1) && !item.file.isPaused) {
        await _this.oneChunkUpload(item, file, chunkSize, index)
        progressBar = Math.ceil((100 / totalChunks) * currentChunk)
        _this.$emit("updateFile", index, "progressBar", progressBar)
        currentChunk++;
      }
    },

    async oneChunkUpload(item, file, chunkSize, index) {
      if (item.file.abortFile) {
        Object.assign(_this.UploadFiles[index].file, {isPaused: true})
        await this.abortFile(item)
      }
      const _this = this;
      const start = item.currentChunk * chunkSize;
      const end = Math.min(file.size, start + chunkSize);
      const chunkBlob = file.slice(start, end);

      const chunkBinary = await chunkBlob.arrayBuffer()
      const chunk = this.arrayBufferToBase64(chunkBinary)

      if (item.currentChunk === item.totalChunks) {
        const postData = {
          "key": item.key,
          "prevETags": item.prevETags,
          "uploadId": item.uploadId,
          "fileName": item.FileName,
          "objectType": "Case",
          "objectId": this.getCase.CaseID,
          "length": item.FileSize,
          "description": null,
          "permissions": item.data,
        }
        const response = await this.completeUpload(postData)
        if (!response || !response.data || !response.data.fileID) {
          console.error(`Failed to upload chunk ${item.currentChunk}`);
          return;
        }
        this.$emit("updateFile", index, "inProgress", false)
        if ((_this.UploadFiles.length - 1) === index) _this.$emit("uploadFinished")
        this.refreshFileData();
      } else {
        const postData = {
          "partNumber": item.currentChunk + 1,
          "key": item.key,
          "prevETags": item.prevETags,
          "uploadId": item.uploadId,
          "chunk": chunk
        }
        const response = await this.uploader(postData)
        if (!response || !response.data || !response.data.eTags) {
          console.error(`Failed to upload chunk ${item.currentChunk}`);
          return;
        }
        const prevETags = response.data.eTags
        Object.assign(_this.UploadFiles[index], {prevETags})
      }
      Object.assign(_this.UploadFiles[index], {currentChunk: item.currentChunk + 1})
    },

    async generateUploadId(file) {
      const postData = {
        "fileName": file.FileName,
      }
      return apiService.post("large-file/generate-uploadId", postData)
    },

    arrayBufferToBase64( buffer ) {
      let binary = '';
      const bytes = new Uint8Array( buffer );
      const len = bytes.byteLength;
      for (let i = 0; i < len; i++) {
        binary += String.fromCharCode( bytes[ i ] );
      }
      return window.btoa( binary );
    },

    async uploader(postData) {
      return apiService.post("large-file/upload-chunk", postData)
    },

    async completeUpload(postData) {
      return apiService.post("large-file/complete-upload", postData)
    },

    async abortFile(item) {
      await this.abortUpload(item.key, item.uploadId)
    },

    async playHandler(index) {
      await this.uploadVideoChunks(this.UploadFiles[index], index)
    },

    async abortUpload(key, uploadId) {
      const postData = {
        "key": key,
        "uploadId": uploadId
      }
      return apiService.post("large-file/abort-upload", postData)
    },

    refreshFileData() {
      if (this.getCase.CaseID) {
        apiService
            .get("case/" + this.getCase.CaseID + "/detail")
            .then((response) => {
              this.fillFiles(response.data.Files);
              this.setCase(response.data);
            });
        if (this.getCurrentScopes.indexOf('st2.viewrelatedcases') !== -1) {
          this.refreshRelatedFilesData();
        }
      }
    },

    refreshRelatedFilesData() {
      this.isLoadingRelatedFiles = true;
      apiService
          .get("case/" + this.getCase.CaseID + "/related-files")
          .then((response) => {
            this.RelatedFiles = response.data.RelatedFiles;
            this.FilteredRelatedFiles = this.getFilteredRelatedFiles(this.FilterFiles).filter(i => i.UserTypePermissions.indexOf(this.UserType) !== -1);
            this.isLoadingRelatedFiles = false;
          });
    },

    fillFiles(val) {
      if (this.UserType) {
        val = val.filter(i => i.UserTypePermissions && i.UserTypePermissions.indexOf(this.UserType) !== -1)
      }
      this.AllFiles = this.Files = val || [];
    },

    cancelUploadFiles() {
      this.filesUploader = false;
    },

    downloadFile(file) {
      if (this.onLine) {
        const link = document.createElement('a');
        link.href = file.URL;
        link.setAttribute('download', file.FileName);
        document.body.appendChild(link);
        link.click();
      } else {
        this.downloadFileOffline(file)
      }
    },

    downloadFileOffline(file) {
      caches.match(file.URL).then(cachedResponse => {
        if (cachedResponse) {
          cachedResponse.blob().then(blob => {
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;
            a.download = file.FileName;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
          }).catch(error => {
            console.error('Failed to create Blob from cached response:', error);
          });
        } else {
          console.log('Failed to match file in cache');
        }
      }).catch(error => {
        console.error('Failed to match file in cache:', error);
      });
    }
  },
}
</script>

<style scoped>
.min-height-10 {
  min-height: 10rem;
}
.pt-05 {
  padding-top: 0.5rem;
}
</style>