<template>
  <b-row>
    <b-col
      v-if="!file.file.inProgress && !file.file.abortFile"
      md="1"
      class="basket-icon"
      @click="removeFile(index)"
    >
      <img
        src="../../../assets/images/logo/redBasket.png"
        alt="basket"
      >
    </b-col>
    <b-col md="6">
      <div class="upload-in-progress">
        <feather-icon
          size="30"
          icon="FileIcon"
        />
        <div class="file-wrapper">
          <div class="file-name-wrapper">
            {{ file.file.name }}
          </div>
          <div class="progress-bar-wrapper">
            <progress-bar
              :options="options"
              :value="file.file.progressBar"
            />
          </div>
        </div>
        <div v-if="file.file.inProgress && !file.file.abortFile" class="btn-wrapper">
          <feather-icon
            v-if="file.file.isPaused"
            size="30"
            icon="PlayIcon"
            @click="playHandler(index)"
          />
          <feather-icon
            v-else
            size="30"
            icon="PauseIcon"
            @click="pauseHandler(index)"
          />
          <feather-icon
            size="30"
            icon="StopCircleIcon"
            @click="stopHandler(index)"
          />
        </div>
      </div>
    </b-col>
    <b-col>
      <b-form-checkbox
        :id="'user' + index"
        v-model="User"
        name="user"
        class="description-text"
        :disabled="true"
      >
        <p class="font-small-3">
          User
        </p>
      </b-form-checkbox>
    </b-col>
    <b-col v-if="!publicCase">
      <b-form-checkbox
        :id="'investigator' + index"
        v-model="Investigator"
        name="investigator"
        class="description-text"
        :disabled="false"
      >
        <p class="font-small-3">
          Investigator
        </p>
      </b-form-checkbox>
    </b-col>
    <b-col v-if="!publicCase">
      <b-form-checkbox
        :id="'client' + index"
        v-model="Client"
        name="client"
        class="description-text"
        :disabled="false"
      >
        <p class="font-small-3">
          Client
        </p>
      </b-form-checkbox>
    </b-col>
  </b-row>
</template>

<script>
import {BCol, BFormCheckbox, BRow} from "bootstrap-vue";

export default {
  components: {
    BRow,
    BCol,
    BFormCheckbox,
  },
  props: {
    file: {
      type: Object,
      default: null,
    },
    index: {
      type: Number,
      default: 0,
    },
    publicCase: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      User: true,
      Investigator: "",
      Client: "",
      data: "",

      options: {
        progress: {
          color: '#6dcff6',
          backgroundColor: '#d9d9d9',
        },
        layout: {
          height: 20,
          width: 280,
          verticalTextAlign: 72,
          horizontalTextAlign: 43,
          progressPadding: 0,
        }
      },
    }
  },
  watch: {
    User(val) {
      this.changeData("User;", val)
    },
    Investigator(val) {
      this.changeData("Investigator;", val)
    },
    Client(val) {
      this.changeData("Client;", val)
    },
  },
  mounted() {
    this.changeData("User;", true)
  },
  methods: {
    removeFile(index) {
      this.$emit("removeFile", index);
    },

    changeData(text, inc) {
      if (inc) {
        if (this.data.indexOf(text) === -1) {
          this.data = this.data + text
        }
      } else {
        if (this.data.indexOf(text) !== -1) {
          this.data = this.data.replace(text, "")
        }
      }
      this.$emit("changeData", this.index, this.data);
    },

    playHandler(index) {
      this.$emit("playHandler", index);
    },

    pauseHandler(index) {
      this.$emit("pauseHandler", index);
    },

    stopHandler(index) {
      this.$emit("stopHandler", index);
    },
  }
}
</script>

<style scoped>
.upload-in-progress {
  display: flex;
  justify-content: space-around;
  align-items: end;
  padding: 3px;
}
.file-name-wrapper {
  display: block;
  width: 280px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.progress-bar-wrapper {
  border: 1px solid #6dcff6;
  width: 286px;
  padding: 0 2px;
}
.btn-wrapper {
  display: flex;
}
.description-text {
  top: 1.2rem;
}
.basket-icon {
  top: 1rem;
  cursor: pointer;
  left: 2.5rem;
}
</style>