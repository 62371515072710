<template>
  <b-row>
    <b-col md="4">
      <p>Dates/Times</p>
      <div
        class="collapse-textarea"
        :class="{expanded: !isExpandedWhen}"
      >
        <textarea
          v-model="ScheduledApptWhen"
          class="expandable-textarea"
          :class="[isExpandedWhen ? 'not-expanded' : 'expanded']"
          contentEditable
          :readonly="readOnly"
        />
        <div @click="isExpandedWhen = !isExpandedWhen">
          <span
            class="expand-arrow mt-n1"
            :class="[isExpandedWhen ? 'not-expanded' : 'expanded']"
          >&#187;</span>
        </div>
      </div>
    </b-col>
    <b-col
      md="7"
      class="float-right"
    >
      <p>Locations</p>
      <div
        class="collapse-textarea"
        :class="{expanded: !isExpandedWhere}"
      >
        <textarea
          v-model="ScheduledApptWhere"
          class="expandable-textarea"
          :class="[isExpandedWhere ? 'not-expanded' : 'expanded']"
          contentEditable
          :readonly="readOnly"
        />
        <div @click="isExpandedWhere = !isExpandedWhere">
          <span
            class="expand-arrow mt-n1"
            :class="[isExpandedWhere ? 'not-expanded' : 'expanded']"
          >&#187;</span>
        </div>
      </div>
    </b-col>
    <hr>
  </b-row>
</template>

<script>
import {BCol, BRow} from "bootstrap-vue";
import {mapGetters} from "vuex";

export default {
  components: {
    BRow,
    BCol,
  },
  props: {
    index: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      readOnly: false,
      ScheduledApptWhere: "",
      ScheduledApptWhen: "",
      isExpandedWhere: false,
      isExpandedWhen: false,
    }
  },
  computed: {
    ...mapGetters({
      getAppointments: "cases/getAppointments",
      getIsReadOnly: "cases/getIsReadOnly",
    }),
    isReadOnly() {
      return this.getIsReadOnly;
    },
  },
  watch: {
    appointment(val) {
      this.ScheduledApptWhen = val.ScheduledApptWhen;
      this.ScheduledApptWhere = val.ScheduledApptWhere;
    },
    isReadOnly(val) {
      this.readOnly = val
    },
    ScheduledApptWhen(val) {
      const item = {
        ScheduledApptWhen: val,
        ScheduledApptWhere: this.ScheduledApptWhere,
      };
      this.$emit("updateAppointment", item, this.index);
    },
    ScheduledApptWhere(val) {
      const item = {
        ScheduledApptWhen: this.ScheduledApptWhen,
        ScheduledApptWhere: val,
      };
      this.$emit("updateAppointment", item, this.index);
    },
  },
  async created() {
    this.readOnly = this.getIsReadOnly;
    try {
      const Appointments = this.getAppointments;
      this.ScheduledApptWhen = Appointments[this.index].ScheduledApptWhen;
      this.ScheduledApptWhere = Appointments[this.index].ScheduledApptWhere;
    } catch (err) {
      this.error = err
    }
  },
}
</script>

<style scoped>
p {
  font-size: 0.857rem;
  margin-bottom: 0px;
}
input {
  padding: 0.438rem 1rem;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #d8d6de;
  border-radius: 0.357rem;
}
input:focus {
  background-color: #fff;
  border-color: #476DAE;
}
</style>