<template>
  <div>
    <input
      id="Zip"
      type="text"
      placeholder=""
      :value="zipValue"
      :readonly="readonly"
      :class="{blur: readonly}"
      @input="eventTarget"
    >
  </div>
</template>

<script>

export default {
    props: {
        value: {
            type: String,
            default: "",
        },
        readonly: Boolean,
    },
    data() {
        return {
            zipValue: "",
        }
    },
    watch: {
        value(val) {
            this.zipFilter(val);
        },
    },
    mounted() {
        this.zipFilter(this.value);
    },
    methods: {
        eventTarget($event) {
            const val = $event.target.value;
            $event.target.value = this.zipFilter(val);
        },
        zipFilter(val) {
          if (val === null ||val === "" || val === undefined || val.length === 1 && isNaN(val)) {
            this.$emit('input', null)
            return null
          }

          if (val.length === 6 && val.substr(5, 1) === "-") {
            this.zipValue = val;
            this.$emit('input', val)
            return val;
          }

          const regex = /\d+/;
          val = val.split("-").join("");
          const oldVal = val;

          val = val.match(regex).toString();
          if (oldVal.Length > val.length)
            val = val.padStart(oldVal.Length - val.length, '0');
          let output = val;

          if (val.length > 5) {
            output = val.substr(0, 5) + "-" + val.substr(5, 4)
          }

          this.zipValue = output;
          this.$emit('input', output)
          return output;
        },
    },
}
</script>

<style scoped>
input:focus-visible {
    outline: none;
    border: 1px solid #476dae;
    box-shadow: 0 2px 5px -2px #476dae;
}
input {
    border: 1px solid #d8d6de;
    border-radius: 0.3rem;
    width: 100%;
    padding: 0.5rem;
}
.blur {
    background-color: #efefef;
}
#ZIP {
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.45;
    color: #6e6b7b;
}
</style>