<template>
  <div>
    <div class="row mb-1">
      <div class="col-6">
        <!-- add button -->
        <div v-if="getCurrentScopes.indexOf('st2.createaction') !== -1">
          <div class="col-4 pr-0 pl-0 D-flex justify-space-arround">
            <b-button
              block
              variant="primary"
              style="
            justify-content: space-around;
            display: flex;
            border-radius: 0;
            width: max-content;
          "
              :disabled="getCase.Status === 'Closed' || getCase.IsLegalHold || !onLine"
              @click="$router.push('/cases/add-action/' + getCase.CaseID)"
            >
              <span>Add Action</span>
            </b-button>
          </div>
        </div>
      </div>
    </div>

    <!-- table -->

    <b-col class="border-top-dark min-height-10 rounded-sm">
      <b-row
        class="bg-light border-left-dark border-right-dark height-50 d-flex align-items-center"
        :class="{'border-bottom-dark': !rows.length}"
      >
        <b-col md="2">
          Action
        </b-col>
        <b-col md="1">
          Hours
        </b-col>
        <b-col md="1">
          Status
        </b-col>
        <b-col md="2">
          Assigned
        </b-col>
        <b-col
          md="2"
          class="text-nowrap"
        >
          Scheduled Date
        </b-col>
        <b-col>Description</b-col>
        <b-col class="text-nowrap">
          Video link
        </b-col>
        <b-col
          v-if="UserType !== 'Client'"
          md="1"
        >
          Actions
        </b-col>
      </b-row>
      <b-row
        v-for="(item, index) of rows"
        :key="index"
        class="border-left-dark border-right-dark d-flex align-items-center"
        :class="{'border-bottom-dark': index + 1 === rows.length}"
      >
        <b-col>
          <b-row
            class="pt-1 border-top"
            :class="{'primary': index === isOpen}"
          >
            <b-col md="2">
              <router-link :to="UserType !== 'Client' ? '/cases/edit-action/' + item.ActionID : ''">
                <div class="clip">
                  {{ item.ActionFileNumber }}
                </div>
              </router-link>
            </b-col>
            <b-col md="1">
              <div class="clip">
                {{ item.AuthorizedHours }}
              </div>
            </b-col>
            <b-col
              md="1"
              style="padding: 0.5rem;position: relative;top: -0.5rem;left: -1rem"
            >
              <span :style="getStatus(item.StatusCode)">{{ item.Status }} </span>
              <div class="clip">
                {{ item.ApprovedByManagerID ? "(Approved)" : "(Not Approved)" }}
              </div>
            </b-col>
            <b-col
              md="2"
              class="text-truncate"
            >
              <div
                v-for="Assigned in item.Assignments"
                :key="Assigned.UserAccountID"
              >{{ Assigned.Fullname }}</div>
            </b-col>
            <b-col md="2">
              <span class="text-nowrap">{{ item.ScheduledDate | dateFormat }}</span>
            </b-col>
            <b-col md="2">
              <div class="clip">
                {{ item.Title }}
              </div>
            </b-col>
            <b-col md="1">
              <div
                v-for="(Activity, index) in item.Activities"
                :key="index"
              >
                <span
                  v-if="Activity.VideoStatus"
                  class="text-nowrap"
                  :class="{'video-link-img': UserType !== 'Client'}"
                >
                  <img
                    v-if="Activity.VideoStatus === 1"
                    alt="Camera"
                    src="../../../assets/images/icons/Video_Icon_Integrity.png"
                    class="pr-1 cursor-pointer video-icon"
                    @click="goToVideo(Activity.ActivityId)"
                  >
                  <img
                    v-else-if="Activity.VideoStatus === 2"
                    alt="Camera"
                    src="../../../assets/images/icons/Video_Icon_Claimant.png"
                    class="pr-1 cursor-pointer video-icon"
                    @click="goToVideo(Activity.ActivityId)"
                  >
                  <img
                    v-else
                    alt="Mic"
                    src="../../../assets/images/icons/mic-icon.png"
                    class="pr-1 cursor-pointer video-icon"
                    @click="goToVideo(Activity.ActivityId)"
                  >
                  <img
                    v-if="getSkin === 'dark'"
                    alt="copy link"
                    src="../../../assets/images/icons/copyLink_dark.png"
                    class="cursor-pointer"
                    @click="copyLink(Activity.VideoUrl)"
                  >
                  <img
                    v-else
                    alt="copy link"
                    src="../../../assets/images/icons/copyLink_light.png"
                    class="cursor-pointer"
                    @click="copyLink(Activity.VideoUrl)"
                  >
                </span>
              </div>
            </b-col>
            <b-col
              v-if="UserType !== 'Client'"
              md="1"
            >
              <span>
                <b-dropdown
                  v-if="getCase.Status !== 'Closed'"
                  variant="link"
                  toggle-class="text-decoration-none"
                  no-caret
                >
                  <template v-slot:button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="16"
                      class="text-body align-middle mr-25"
                    />
                  </template>
                  <b-dropdown-item>
                    <span @click="approveAction(item.ActionID)">
                      <span v-if="item.ApprovedByManagerID">Unapprove</span>
                      <span v-else>Approve</span>
                    </span>
                  </b-dropdown-item>
                  <b-dropdown-item>
                    <span @click="rejectAction(item)">Reject...</span>
                  </b-dropdown-item>
                  <b-dropdown-item v-if="!getCase.IsLegalHold">
                    <span @click="deleteActionWithPrompt(item)">Delete</span>
                  </b-dropdown-item>
                </b-dropdown>
              </span>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-col>
    <b-modal
      id="modal-delete-action"
      ok-variant="danger"
      ok-title="Delete Action"
      modal-class="modal-danger"
      centered
      title="Confirm Delete Action"
      @ok="confirmDeleteAction()"
    >
      <div v-if="actionToDelete !== null">
        Are you sure you want to delete action {{ actionToDelete.ActionFileNumber }} ? This cannot be undone.  Any assigned investigators will be removed from the schedule.
      </div>
    </b-modal>
    <b-modal
      id="modal-reject-action"
      ok-variant="danger"
      ok-title="Reject"
      modal-class="modal-danger"
      centered
      title="Reject Action"
      @ok="e => confirmRejectAction(e)"
    >
      <div v-if="actionToReject !== null">
        <b-form-group
          class="required-data"
          label="Reason"
          label-for="reasonToReject"
        >
          <textarea
            id="reasonToReject"
            v-model="reasonToReject"
            style="resize: none;width: 100%;min-height: 20rem; background: none;"
          />
        </b-form-group>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {BButton, BCol, BDropdown, BDropdownItem, BFormGroup, BRow} from "bootstrap-vue";
import "vue-good-table/dist/vue-good-table.css";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import store from "@/store/index";
import APIService from "@core/utils/APIService";
import {mapGetters, mapMutations} from "vuex";
import {weekdayText} from "@/views/schedule/assetsSchedules";

const apiService = new APIService();


export default {
  components: {
    BRow,
    BCol,
    BButton,
    BDropdown,
    BDropdownItem,
    BFormGroup,
  },
  filters: {
    dateFormat(val) {
      if (val) {
        const date = new Date(val);
        const options = {month: '2-digit', day: '2-digit', year: 'numeric'}
        const day = weekdayText[date.getDay() ? date.getDay() - 1 : 6];
        if (date) return date.toLocaleDateString("en-US", options) + " - " + day;
      }
      if (val) return val.substr(0, 10);
      return val
    },
    isEmpty(val) {
      if (!val) {
        return "Never"
      }
      return val;
    }
  },
  data() {
    return {
      actionToDelete: null,
      actionToReject: null,
      reasonToReject: "",
      dir: false,
      rows: [],
      isOpen: -1,
      UserType: "",
      status: [
        {
          1: "Active",
          2: "Disabled",
        },
        {
          1: "light-primary",
          2: "light-danger",
        },
      ],
      onLine: navigator.onLine,
    };
  },

  computed: {
    ...mapGetters({
      getCase: "cases/getCase",
      getSkin: "appConfig/getSkin",
      getCurrentScopes: "scopes/getCurrentScopes",
      getOnlineStatus: "offline/getOnlineStatus",
    }),

    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Current: "light-primary",
        Professional: "light-success",
        Rejected: "light-danger",
        Resigned: "light-warning",
        Applied: "light-info",
        /* eslint-enable key-spacing */
      };

      return (status) => statusColor[status];
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true;
        return this.dir;
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false;
      return this.dir;
    },
  },

  watch: {
    getOnlineStatus(val) {
      this.onLine = val;
    },
  },

  mounted() {
    this.UserType = localStorage.getItem("userType");
    this.loadActionLogs()
  },

  methods: {
    ...mapMutations({}),

    loadActionLogs() {
      this.rows = [];
      apiService
          .get("actions/case/" + this.getCase.CaseID)
          .then((response) => {
            this.rows = response.data.sort((a,b) => (a.ScheduledDate > b.ScheduledDate) ? 1 : ((b.ScheduledDate > a.ScheduledDate) ? -1 : 0))
          });
    },

    toggleRow(index) {
      this.isOpen = this.isOpen === index ? -1 : index;
    },

    showToast(variant, position, timeout, data) {
      this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: "Notification",
              icon: "InfoIcon",
              text: data,
              variant,
            },
          },
          {
            position,
            timeout,
          }
      );
    },
    approveAction(ActionID) {
      this.$router.push('/cases/approve-action/' + ActionID);
    },
    deleteActionWithPrompt(actionObj) {
      this.actionToDelete = actionObj;
      this.$bvModal.show("modal-delete-action");
    },

    rejectAction(actionObj) {
      this.actionToReject = actionObj;
      this.$bvModal.show("modal-reject-action");
    },

    confirmRejectAction(e) {
      if (!this.reasonToReject) {
        e.preventDefault()
        this.showToast('danger', 'top-center', 4000, 'Reason required!');
        return;
      }
      const postData = {
        ActionID: this.actionToReject.ActionID,
        Reason: this.reasonToReject
      }
      apiService
          .post("action/reject", postData)
          .then((res) => {
            if (res) {
              this.showToast('success', 'top-center', 4000, 'Action Reject!');
              this.loadActionLogs();
            }
          });
      this.actionToReject = null;
      this.reasonToReject = "";
    },

    confirmDeleteAction() {
      apiService
          .delete("action/" + this.actionToDelete.ActionID)
          .then((res) => {
            if (res) {
              this.showToast('success', 'top-center', 4000, 'Action delete!');
              this.loadActionLogs();
            }
          });
      this.actionToDelete = null;
    },

    getStatus(val) {
      const styleAtr = "color:";
      switch (val) {
        case 0 :
          return styleAtr + "#dbdb04";
        case 1 :
          return styleAtr + "blue";
        case 2 :
          return styleAtr + "orange";
        case 3 :
          return styleAtr + "purple";
        case 4 :
          return styleAtr + "red";
        case 5 :
          return styleAtr + "green";
        case 6 :
          return styleAtr + "gray";
      }
    },

    goToVideo(activityID) {
      this.$router.push('/cases/activity/videos/' + activityID);
    },

    copyLink(VideoURL) {
      const el = document.createElement("input");
      document.body.appendChild(el);
      el.value = window.location.protocol + '//' + window.location.host + VideoURL;
      el.select();
      document.execCommand("copy");
      document.body.removeChild(el);
      this.showToast('success', 'top-center', 4000, 'Link copied!');
    },
  },
};
</script>

<style>
ul.dropdown-menu li {
  height: 2rem;
}
.primary {
  background: #476dae;
  color: white;
}
.primary circle {
  color: white;
}
.video-link-img {
  position: relative;
  left: -3rem;
}
.video-icon {
  width: 3rem;
  padding: 0.2rem;
}
.clip {
  padding-right: 1.5rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.empty-block {
  height: 1.5rem;
}
</style>
