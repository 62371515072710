<template>
  <b-row class="pb-2 pt-2">
    <b-col md="5">
      <b-form-group
        label="Insured Name"
        label-for="insuredName"
      >
        <v-select
          id="insuredName"
          v-model="InsuredName"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          label="title"
          :options="insuredOptions"
          placeholder=""
          name="insuredName"
          :disabled="readOnly"
        >
          <template #search="{ attributes, events }">
            <input
              v-model="insuredSearch"
              maxlength="10"
              class="vs__search"
              v-bind="attributes"
              :readonly="readOnly"
              v-on="events"
            >
          </template>
        </v-select>
      </b-form-group>
    </b-col>
  </b-row>
</template>

<script>
import APIService from "@core/utils/APIService";
import {BCol, BFormGroup, BRow} from "bootstrap-vue";
import vSelect from "vue-select";
import {mapGetters} from "vuex";

const apiService = new APIService();

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    vSelect,
  },
  data() {
    return {
      readOnly: false,
      Insured: null,
      InsuredName: "",
      insuredOptions: [],
      insuredSearch: "",
      hiddenSearch: "",
    }
  },
  computed: {
    ...mapGetters({
      getCase: "cases/getCase",
      getIsReadOnly: "cases/getIsReadOnly",
    }),
    isReadOnly() {
      return this.getIsReadOnly;
    },
  },
  watch: {
    getCase: {
      handler(val) {
        if (val.InsuredClientID !== (this.Insured && this.Insured.InsuredID)) {
          this.searchInsuredById(val.InsuredClientID)
        }
      },
      deep: true,
    },
    isReadOnly(val) {
      this.readOnly = val
    },
    InsuredName(val) {
      this.insuredSearch = "";
      this.Insured = {
        Name: !val ? null : val.value,
        InsuredID: !val ? null : val.id,
        DOUsername: !val ? null : val.DOUsername,
      };
      if (!val || this.Insured.InsuredID) {
        this.$emit("changedInsured", this.Insured);
      }
    },
    insuredSearch(val) {
      if (val.length >= 3) {
        this.isEditCase = false;
        this.searchInsured(val)
      }
    },
    hiddenSearch(val) {
      if (val && val.length >= 3) {
        this.isEditCase = false;
        this.searchInsured(val)
      }
    },
  },
  created() {
    this.readOnly = this.getIsReadOnly;
    if (this.getCase.InsuredClientID) this.searchInsuredById(this.getCase.InsuredClientID)
  },
  methods: {
    searchInsuredById(id) {
      if (!this.isEditCase && id) {
        apiService
            .get("insured/" + id + "/detail")
            .then(res => {
              this.InsuredName = {
                title: res.data.Name,
                value: res.data.Name,
                id: res.data.ClientID,
                DOUsername: res.data.DOUsername
              }
            })
      }
    },
    searchInsured(searchNameTerm) {
      if (!this.isEditCase) {
        apiService
            .get(
                "insured/search?searchNameTerm=" +
                encodeURIComponent(searchNameTerm) +
                "&activeClients=" + false +
                "&max=1000"
            )
            .then((response) => {
              this.insuredOptions = response.data.Clients.map(item => {
                return {title: item.Name, value: item.Name, id: item.ClientID, DOUsername: item.DOUsername};
              });
              if (this.insuredOptions.length === 1 && this.insuredOptions[0].title.toLowerCase() === this.hiddenSearch.toLowerCase()) {
                this.InsuredName = this.insuredOptions[0]
              }
            });
      }
    },
  }
}
</script>

<style scoped>

</style>