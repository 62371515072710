<template>
  <b-row class="pb-2 pt-2" @click="close">
    <b-col>
      <b-row>
        <b-col md="6">
          <validation-provider
            #default="{ errors }"
            name="Client Name"
            rules="required"
          >
            <b-form-group
              class="required-data"
              label="Client Name"
              label-for="clientName"
              :state="errors.length > 0 ? false : null"
            >
              <v-select
                id="clientName"
                v-model="ClientName"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="title"
                :options="clientName"
                placeholder=""
                name="dealOwner"
                :disabled="isReadOnlyField('Client')"
                @change="alert('asd')"
              >
                <template #search="{ attributes, events }">
                  <input
                    v-model="clientSearch"
                    class="vs__search"
                    v-bind="attributes"
                    :state="errors.length > 0 ? false : null"
                    :readonly="readOnly"
                    v-on="events"
                  >
                </template>
              </v-select>
              <small
                class="text-danger"
                :state="errors.length > 0 ? false : null"
              >
                {{ errors[0] }}
              </small>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col md="0.5">
          <div
            v-if="!readOnly && CaseID && getCurrentScopes.indexOf('st2.movecasebranch') !== -1"
            class="cursor-pointer unlocks-icon"
            @click="reassignCase()"
          >
            <img src="../../../assets/images/logo/unlocks-icon.png">
          </div>
        </b-col>
        <b-col>
          <validation-provider
            #default="{ errors }"
            name="Requestor Name"
            rules="required"
          >
            <b-form-group
              class="required-data"
              label="Requestor Name"
              label-for="requesterName"
              :state="errors.length > 0 ? false : null"
            >
              <v-select
                id="requesterName"
                v-model="RequesterName"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="title"
                :options="requesterName"
                placeholder=""
                name="requesterName"
                :disabled="isReadOnlyField('Requestor')"
                :state="errors.length > 0 ? false : null"
              />
              <small
                class="text-danger"
                :state="errors.length > 0 ? false : null"
              >
                {{ errors[0] }}
              </small>
            </b-form-group>
          </validation-provider>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <validation-provider
            #default="{ errors }"
            name="Branch Name"
            rules="required"
          >
            <b-form-group
              class="required-data"
              label="Branch Name"
              label-for="branchName"
              :state="errors.length > 0 ? false : null"
            >
              <v-select
                id="branchName"
                v-model="BranchName"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="title"
                :options="branchName"
                placeholder=""
                name="branchName"
                :disabled="isReadOnlyField('Branch')"
                :state="errors.length > 0 ? false : null"
              />
              <small
                class="text-danger"
                :state="errors.length > 0 ? false : null"
              >
                {{ errors[0] }}
              </small>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col>
          <b-form-group
            label="Requestor Email"
            label-for="requesterEmail"
          >
            <b-form-input
              id="requesterEmail"
              v-model="Requester.Email"
              placeholder=""
              name="requesterEmail"
              :readonly="true"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="6">
          <b-form-group
            label="Street Address"
            label-for="streetAddress"
          >
            <b-form-input
              id="streetAddress"
              v-model="Branch.Address"
              placeholder=""
              name="streetAddress"
              :readonly="true"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label="Requestor Phone"
            label-for="requesterPhone"
          >
            <b-form-input
              id="requesterPhone"
              v-model="Requester.Phone"
              v-mask="'(###) ###-####'"
              placeholder=""
              name="requesterPhone"
              :readonly="true"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="2">
          <b-form-group
            label="City"
            label-for="city"
          >
            <b-form-input
              id="city"
              v-model="Branch.City"
              placeholder=""
              name="city"
              :readonly="true"
            />
          </b-form-group>
        </b-col>
        <b-col md="2">
          <b-form-group
            label="State"
            label-for="state"
          >
            <b-form-input
              id="state"
              v-model="Branch.StateProvince"
              placeholder=""
              name="state"
              :readonly="true"
            />
          </b-form-group>
        </b-col>
        <b-col md="2">
          <b-form-group
            label="Zip"
            label-for="zip"
          >
            <ZipInput
              id="zip"
              v-model="Branch.ZipCode"
              :readonly="true"
            />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            label="CC Email"
            label-for="requesterEmail"
          >
            <b-form-input
              id="ccEmail"
              :class="errCc ? 'err-input' : ''"
              v-model="CcEmails"
              placeholder=""
              name="ccEmail"
              :readonly="readOnly"
              @blur="() => blurStop(false)"
            />
          </b-form-group>
          <ul name="text" class="user-list-wrap">
            <li
              v-for="user in ccUserOptionList"
              :key="user.title"
              class="user-list"
              @click="selectedUser(user.title)"
            >{{ user.title }}</li>
          </ul>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import { BCol, BFormGroup, BFormInput, BRow } from "bootstrap-vue";
import vSelect from "vue-select";
import APIService from "@core/utils/APIService";
import {mapGetters, mapMutations} from "vuex";
import { ValidationProvider } from "vee-validate";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import ZipInput from "@core/components/zipInput/ZipInput.vue";

const apiService = new APIService();

export default {
  components: {
    ZipInput,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    vSelect,
    ValidationProvider,
  },
  data() {
    return {
      clientObject: {},
      branchObject: {},
      userObj: {},
      Branch: {
        BranchID: "",
        Name: "",
        Address: "",
        City: "",
        StateProvince: "",
        ZipCode: "",
      },
      Requester: {
        UserAccountID: "",
        Name: "",
        Email: "",
        Phone: "",
      },
      Client: {
        ClientID: "",
        Name: "",
      },
      CaseID: "",

      ClientName: "",
      BranchName: "",
      RequesterName: "",
      RequesterEmail: "",
      RequesterPhone: "",
      CcEmails: "",
      searchTerm: "",

      errCc: false,

      readOnly: false,

      clientSearch: "",

      clientName: [],
      requesterName: [],
      branchName: [],
      ccUserOptionList: [],
      ccUserList: [],
    }
  },
  computed: {
    ...mapGetters({
      getCase: "cases/getCase",
      getIsReadOnly: "cases/getIsReadOnly",
      getClientReadOnly: "cases/getClientReadOnly",
      getUserType: "auth/getUserType",
      getUserRole: "auth/getUserRole",
      getCurrentScopes: "scopes/getCurrentScopes",
    }),
    getCaseData() {
      return this.getCase;
    },
    isReadOnly() {
      return this.getIsReadOnly;
    },
  },
  watch: {
    getCaseData(val) {
      this.setCaseData(val)
    },
    isReadOnly(val) {
      this.readOnly = val
    },
    ClientName(val) {
      if (val === null) {
        this.clearBranch()
        this.clearRequester()
      }
      this.clientSearch = "";
      if (val && val.ClientID) this.loadClient(val.ClientID);
    },
    clientSearch(val) {
      if (val.length >= 3) {
        this.searchClients(val)
      }
    },
    RequesterName(val) {
      if (val === null) this.clearRequester()
      if (val && val.id) this.loadRequester(val.id);
    },
    BranchName(val) {
      if (val === null) this.clearBranch();
      if (val && val.id) this.loadBranch(val.id);
    },
    branchObject(val) {
      this.fillBranch(val)
      this.$emit("changedBranch", this.Branch);
    },
    CaseID(val) {
      this.$emit("changedCaseID", val);
    },
    CcEmails(val, oldVal) {
      this.errCc = false;
      this.ccUserOptionList = [];
      this.fieldWatcher(val, oldVal);
    },
  },
  async created() {
    this.readOnly = this.getIsReadOnly;
    try {
      if (this.getUserType === "Client") {
        this.searchClients("");
        this.getUser("");
      }
      this.setCaseData(this.getCase);
      this.loadUserPage();
    } catch (err) {
      this.error = err
    }
  },

  methods: {
    ...mapMutations({
      setCurrentClient: "cases/setCurrentClient",
    }),
    showToast(variant, position, timeout, data) {
      this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Notification',
              icon: 'InfoIcon',
              text: data,
              variant,
            },
          },
          {
            position,
            timeout,
          })
    },
    getUser() {
      apiService
          .get("user/profile")
          .then( res => {
            this.userObj = res.data;
          })
    },
    searchClients(searchNameTerm) {
        apiService
            .get(
                "clients/search?searchNameTerm=" +
                encodeURIComponent(searchNameTerm) +
                "&activeClients=" +
                !this.activeClients
            )
            .then((response) => {
              this.clientName = response.data.Clients.map((item, int) => {
                return {title: item.Name, value: item.Name, id: int, ClientID: item.ClientID}
              });
              if (this.clientName.length === 1) {
                this.ClientName = this.clientName[0];
                this.loadClient(this.clientName[0].ClientID)
              }
            });
    },

    loadClient(ClientId) {
      if (ClientId !== this.Client.ClientID) {
        if (this.getUserType === "Client") {
          const uid = localStorage.getItem('UID')
          apiService
              .get("client/requestor/" + uid + "/branches")
              .then(res => {
                this.branchName = res.data.map(item => {
                  return {title: item.name, value: item.name, id: item.id}
                });
                if (this.branchName.length === 1 && !this.getCase.Branch.IsDeleted) {
                  setTimeout(() => {
                    this.BranchName = this.branchName[0];
                  }, 10);
                }
                apiService
                    .get("client/" + ClientId + "/detail")
                    .then((response) => {
                      this.Client.ClientID = ClientId;
                      this.Client.Name = response.data.Name;
                      this.setCurrentClient(response.data)
                      this.$emit("changedClient", this.Client);
                    })
              })
        } else {
          apiService
              .get("client/" + ClientId + "/detail")
              .then((response) => {
                this.branchName = response.data.Branches.map(item => {
                  return {title: item.Name, value: item.Name, id: item.ID}
                });
                if (this.branchName.length === 1 && !this.getCase.Branch.IsDeleted) {
                  setTimeout(() => {
                    this.BranchName = this.branchName[0];
                  }, 10);
                }
                this.Client.ClientID = ClientId;
                this.Client.Name = response.data.Name;
                this.setCurrentClient(response.data)
                this.$emit("changedClient", this.Client);
              })
        }
      }
    },
    loadBranch(branchId) {
      if (branchId !== this.Branch.BranchID && !this.getCase.Branch.IsDeleted) {
        apiService
            .get("client/branch/" + branchId + "/detail")
            .then((response) => {
              this.branchObject = response.data;
            });
        apiService
            .get("client/branch/"  + branchId + "/requestors?max=10000")
            .then((response) => {
              this.requesterName = response.data.map(item => {
                return {title: item.Name, value: item.Name, id: item.ID}
              });
              if (this.requesterName.length && this.userObj.UserAccountID && this.getUserRole === "Client User") {
                this.loadRequester(this.userObj.UserAccountID)
              }
            });
      }
    },
    loadRequester(requesterId) {
      apiService
          .get("client/requestor/" + requesterId + "/detail")
          .then((response) => {
            this.Requester = {
              UserAccountID: response.data.UserAccountID,
              Name: response.data.LastName + ", " + response.data.FirstName,
              Email: response.data.EMail,
              Phone: response.data.Phone,
            };
            this.RequesterName = this.Requester.Name;
            this.$emit("changedRequester", this.Requester);
          });
    },
    loadUserPage() {
      apiService
          .get("users/User,Investigator?max=10000")
          .then((response) => {
            this.ccUserList = response.data.Users.map(item => {
              return {title: item.EMail, value: item.EMail}
            })
          });
    },
    fieldWatcher(val, oldVal) {
      if (!val) return
      const usersArr = val.split(";");
      const oldUsersArr = oldVal.split(";");
      if (usersArr.length === oldUsersArr.length) {
        usersArr.map((item, index) => {
          if (item !== oldUsersArr[index]) {
            this.searchTerm = item.trim();
          }
        })
      }
      if (this.searchTerm && this.searchTerm.length && this.searchTerm.length >= 3 && !this.readOnly) {
        this.ccUserOptionList = this.ccUserList.filter(i => i.title.indexOf(this.searchTerm) !== -1);
      }
      this.blurStop(true);
    },
    selectedUser(user) {
      this.ccUserOptionList = [];
      let itemArr = this.CcEmails.split(";")
      itemArr = itemArr.map((item, index) => {
        if (item.trim() === this.searchTerm) {
          item = user;
        }
        return item;
      })
      this.CcEmails = itemArr.join("; ")
      this.searchTerm = "";
    },
    validateEmail(emailAdress) {
      let regexEmail = /\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/;
      return !!emailAdress.match(regexEmail);
    },
    close() {
      this.ccUserOptionList = [];
    },
    fillBranch(val) {
      this.Branch.BranchID = val.BranchID;
      this.Branch.Name = val.Name;
      this.Branch.Address = val.Address1 || val.Address;
      this.Branch.City = val.City;
      this.Branch.StateProvince = val.StateProvince;
      this.Branch.ZipCode = val.ZipCode;
    },
    setCaseData(cases) {
      if (cases && cases.Branch) {
        if (cases.Branch.Client.ClientID) {
          this.ClientName = {
            title: cases.Branch.Client.Name,
            value: cases.Branch.Client.Name,
            id: cases.Branch.Client.ClientID,
            ClientID: cases.Branch.Client.ClientID,
          };
          this.loadClient(cases.Branch.Client.ClientID)
          this.Client.Name = cases.Branch.Client.Name;
          this.Client.ClientID = cases.Branch.Client.ClientID;
        }
        if (cases.Branch.Requester) {
          this.Requester = cases.Branch.Requester;
          this.RequesterName = cases.Branch.Requester.Name;
        }
        let branchItem = "";
        if (cases.Branch.Name) {
          branchItem = {
            title: cases.Branch.Name,
            value: cases.Branch.Name,
            id: cases.Branch.BranchID,
          }
          this.loadBranch(cases.Branch.BranchID)
        }
        if (cases.CaseID) {
          this.CaseID = cases.CaseID
        }

        this.fillBranch(cases.Branch)
        this.BranchName = branchItem;
        this.CcEmails = cases.CcEmails;
      }
    },
    clearBranch() {
      this.BranchName = "";
      this.Branch.BranchID = "";
      this.Branch.Name = "";
      this.Branch.Address = "";
      this.Branch.City = "";
      this.Branch.StateProvince = "";
      this.Branch.ZipCode = "";
    },
    clearRequester() {
      this.Requester.Email = "";
      this.Requester.Phone = "";
      this.RequesterName = "";
    },
    reassignCase() {
      this.$router.push('/cases/reassign-case/' + this.CaseID)
    },
    blurStop(freeValid = false) {
      setTimeout(() => {
        let ErrorMsg = false;
        const itemArr = this.CcEmails.split(";")
        itemArr.map(item => {
          if (item && item.trim() && !this.validateEmail(item)) {
            ErrorMsg = true;
          }
        })
        if (ErrorMsg && !freeValid) {
          this.errCc = true;
          this.showToast('danger', 'top-center', 4000, 'Field must contain a valid list of emails separated by semi-colons.');
        } else {
          this.$emit("changedCcEmail", this.CcEmails);
        }
      },200);
    },
    isReadOnlyField(fieldName) {
      if (this.getUserType === "User") {
        return this.readOnly || !!this.CaseID
      } else if (this.getUserType === "Client") {
        if (fieldName === "Client") {
          return true
        } else if (this.getUserRole === "Client Supervisor") {
          return this.getClientReadOnly
        } else if (this.getUserRole === "Branch Supervisor" && fieldName === "Requestor") {
          return this.getClientReadOnly
        } else if (fieldName === "Branch") {
          return this.branchName.length < 2
        }
        else return true
      } else {
        return true
      }
    }
  }
}
</script>

<style scoped>
.unlocks-icon {
  position: relative;
  top: 1.5rem;
}
.user-list-wrap {
  position: absolute;
  z-index: 2;
  left: -2rem;
  top: 4rem;
  max-height: 18rem;
  overflow: auto;
}
.dark-layout .user-list {
  background-color: #4d5061;
}
.user-list {
  cursor: pointer;
  background: white;
  list-style: none;
  padding: 0.5rem;
}
.err-input {
  border-color: red;
}
</style>

