import APIService from "@core/utils/APIService";
import axios from "axios";

const apiService = new APIService();
const dbName = 'workbox-background-sync';
const storeName = 'requests';

const axiosIns = axios.create({
    timeout: 1000 * 120,
})

var vehicles = [];
var UserOwnVehicles = [];
var syncErrors = [];

export const getSpecialID = () => {
    let specialID = localStorage.getItem("specialID");
    specialID = specialID ? Number(specialID) + 1 : 100;
    localStorage.setItem("specialID", String(specialID));
    return "SID" + specialID;
}

export const checkDatabase = (timelineID = null) => {

    return new Promise((resolve, reject) => {
        const request = indexedDB.open(dbName);

        request.onsuccess = function () {
            const db = request.result;
            if (db.objectStoreNames.contains(storeName)) {
                const transaction = db.transaction([storeName], 'readonly');
                const objectStore = transaction.objectStore(storeName);
                const cursorRequest = objectStore.openCursor();

                let allPromises = [];

                cursorRequest.onsuccess = function (event) {
                    const cursor = event.target.result;
                    if (cursor) {
                        allPromises.push(
                            new Promise((innerResolve) => {
                                const requestData = cursor.value.requestData;
                                const oldBody = JSON.parse(new TextDecoder("utf-8").decode(requestData.body));
                                if (timelineID && oldBody.timelineID === timelineID) {
                                    innerResolve(true);
                                } else {
                                    if (!timelineID) innerResolve(true);
                                    innerResolve(false);
                                }
                                cursor.continue();
                            })
                        );
                    } else {
                        Promise.all(allPromises).then((results) => {
                            resolve(results.includes(true));
                        });
                    }
                };
                cursorRequest.onerror = function (event) {
                    reject(event.target.error);
                };
            } else {
                resolve(false);
            }
            db.close();
        };

        request.onupgradeneeded = function (event) {
            const db = event.target.result;
            if (!db.objectStoreNames.contains(storeName)) {
                db.createObjectStore(storeName);
            }
        };

        request.onerror = function (event) {
            reject(event.target.error);
        };
    });
}

export const changeNewItemInCache = (postData) => {

    return new Promise((resolve, reject) => {
        const request = indexedDB.open(dbName);

        request.onsuccess = function () {
            const db = request.result;
            if (db.objectStoreNames.contains(storeName)) {
                const transaction = db.transaction([storeName], 'readwrite');
                const objectStore = transaction.objectStore(storeName);
                const cursorRequest = objectStore.openCursor();

                cursorRequest.onsuccess = function (event) {
                    const cursor = event.target.result;
                    if (cursor) {
                        const requestData = cursor.value.requestData;
                        const oldBody = JSON.parse(new TextDecoder("utf-8").decode(requestData.body));
                        if (postData.timelineID && oldBody.timelineID === postData.timelineID) {
                            oldBody.ClaimantFirstObserved = postData.ClaimantFirstObserved;
                            oldBody.Synopsis = postData.Synopsis;
                            oldBody.isOffLineEdited = postData.isOffLineEdited;
                            oldBody.VideoObtained = postData.VideoObtained;
                            oldBody.Created = new Date(postData.Created).toISOString();

                            changeTimelineInList(postData)
                            cursor.update({ ...cursor.value, requestData: { ...requestData, body: new TextEncoder().encode(JSON.stringify(oldBody)) } });
                        }

                        if (postData.expenseID && oldBody.expenseID === postData.expenseID) {
                            oldBody.Amount = postData.Amount;
                            oldBody.ExpenseDate = postData.ExpenseDate;
                            oldBody.Description = postData.Description;
                            oldBody.ExpenseCategoryID = postData.ExpenseCategoryID;

                            changeNewExpenseInExpenses(postData);
                            cursor.update({ ...cursor.value, requestData: { ...requestData, body: new TextEncoder().encode(JSON.stringify(oldBody)) } });
                        }

                        cursor.continue();
                    } else {
                        resolve(false);
                    }
                };
            } else {
                resolve(false);
            }
            db.close();
        };

        request.onupgradeneeded = function (event) {
            const db = event.target.result;
            if (!db.objectStoreNames.contains(storeName)) {
                db.createObjectStore(storeName);
            }
        };

        request.onerror = function (event) {
            reject(event.target.error);
        };
    });
}

export const getNewExpense = async (ExpenseID) => {
    return new Promise((resolve, reject) => {
        const request = indexedDB.open(dbName);

        request.onsuccess = function () {
            const db = request.result;
            if (db.objectStoreNames.contains(storeName)) {
                const transaction = db.transaction([storeName], 'readonly');
                const objectStore = transaction.objectStore(storeName);
                const cursorRequest = objectStore.openCursor();

                cursorRequest.onsuccess = function (event) {
                    const cursor = event.target.result;
                    if (cursor) {
                        const requestData = cursor.value.requestData;
                        const oldBody = JSON.parse(new TextDecoder("utf-8").decode(requestData.body));

                        if (oldBody.expenseID === ExpenseID) {
                            resolve(oldBody);
                        }

                        cursor.continue();
                    } else {
                        resolve(false);
                    }
                };
            } else {
                resolve(false);
            }
            db.close();
        };

        request.onupgradeneeded = function (event) {
            const db = event.target.result;
            if (!db.objectStoreNames.contains(storeName)) {
                db.createObjectStore(storeName);
            }
        };

        request.onerror = function (event) {
            reject(event.target.error);
        };
    });
}

export const changeNewExpenseInExpenses = async (newBody) => {
    const cache = await caches.open('case-cache');

    for (const req of await cache.keys()) {
        if (req.url.indexOf("activity/" + newBody.ParentActivityID + "/expenses") !== -1) {
            let cachedOldResponse = await cache.match(req);
            let oldBody = await cachedOldResponse.json();
            oldBody = oldBody.map(item => {
                if (item.ExpenseID === newBody.expenseID) {
                    item.Amount = newBody.Amount;
                    item.Description = newBody.Description;
                    item.ExpenseDate = newBody.ExpenseDate;
                }
                return item
            })
            const myOptions = { status: 200, statusText: 'Ok', headers:  cachedOldResponse.headers};
            const newCacheResponse = new Response(JSON.stringify(oldBody), myOptions);
            await cache.put( req, newCacheResponse );
        }
    }
}

export const changeTimelineInList = async (newBody) => {
    const cache = await caches.open('case-cache');

    for (const req of await cache.keys()) {
        if (req.url.indexOf("activity/" + newBody.ParentActivityID + "/detail") !== -1) {
            let cachedOldResponse = await cache.match(req);
            let oldBody = await cachedOldResponse.json();

            oldBody.ChildActivities = oldBody.ChildActivities.map(item => {
                if (String( item.ActivityID) === String(newBody.timelineID)) {
                    item.ClaimantFirstObserved = newBody.ClaimantFirstObserved;
                    item.Synopsis = newBody.Synopsis;
                    item.Created = newBody.Created;
                    item.VideoObtained = newBody.VideoObtained;
                    item.isOffLineEdited = newBody.isOffLineEdited;
                }
                return item
            })
            const myOptions = { status: 200, statusText: 'Ok', headers:  cachedOldResponse.headers};
            const newCacheResponse = new Response(JSON.stringify(oldBody), myOptions);
            await cache.put( req, newCacheResponse );
        }
    }
}

export const executeRequestsFromIndexedDB = async () => {
    try {
        const db = await openDB(dbName);
        const tx = db.transaction(storeName, 'readwrite');
        const store = tx.objectStore(storeName);
        const requests = await getAllFromStore(store);
        await tx.complete;

        for (const request of requests) {
            await executeRequest(request, request.requestData);
        }
        db.close();
    } catch (error) {
        console.error('Error executing requests from IndexedDB:', error);
    }
}

export const openDB = async (dbName) => {
    return new Promise((resolve, reject) => {
        const request = indexedDB.open(dbName);

        request.onsuccess = (event) => {
            resolve(event.target.result);
        };

        request.onerror = (event) => {
            reject(event.target.error);
        };
    });
}

export const executeRequest = async(request, requestData) => {
    try {
        const response = await fetch(requestData.url, { method: requestData.method, body: requestData.body, headers: requestData.headers });
        if (response.ok) {
            await deleteRequestFromStore(request.id);
        }
        console.log('Request successful:', response);
    } catch (error) {
        console.error('Error executing request:', error);
    }
}

export const getAllFromStore = async(store) => {
    return new Promise((resolve, reject) => {
        const getAllRequest = store.getAll();
        getAllRequest.onsuccess = (event) => {
            resolve(event.target.result);
        };
        getAllRequest.onerror = (event) => {
            reject(event.target.error);
        };
    });
}

export const deleteItem = async(store, requestId) => {
    return new Promise((resolve, reject) => {
        const deleteRequest = store.delete(requestId);
        deleteRequest.onsuccess = () => {
            resolve();
        };
        deleteRequest.onerror = (event) => {
            reject(event.target.error);
        };
    });
}

export const deleteRequestFromStore = async(requestId) => {
    try {
        const db = await openDB(dbName);
        const tx = db.transaction(storeName, 'readwrite');
        const store = tx.objectStore(storeName);
        await deleteItem(store, requestId);
        await tx.complete;
        db.close();
    } catch (error) {
        console.error('Error executing requests from IndexedDB:', error);
    }
}

export const isOldAction = (item) => {
    const inputDate = new Date(item.ScheduledDate);
    const currentDate = new Date();
    const timeDifference = currentDate - inputDate;
    const dayDifference = timeDifference / (1000 * 60 * 60 * 24);
    return Math.abs(dayDifference) <= 3 || (dayDifference) <= 5;
}

export const cacheForOffline = async(rows, callBack) => {
    const uid = localStorage.getItem('UID')
    apiService.get('user/profile').then(res => {
        //using this to capture UserAccountID for offline;
    });
    apiService.get("actions/dashboard?searchFilterTerm=&skip=0&max=100")
    const promises = rows.flatMap(item => {
        const itemPromises = [];

        if (isOldAction(item)) {
            if (item.CaseID) {
                itemPromises.push(
                    apiService.get("case/" + item.CaseID + "/related"),
                    apiService.get("actions/case/" + item.CaseID),
                    apiService.get("vehicles/list").then(veh => {
                        vehicles = veh.data.map(i => ({
                            value: i.VehicleID,
                            unitNumber: i.UnitNumber
                        }));
                    }),
                    apiService.get("users/user"),
                    apiService.get("users/investigator,user"),
                    apiService.get("action/types"),
                    apiService.get("journal/entries/" + item.CaseID),
                    apiService.get("actions/short-info/case/" + item.CaseID),
                    apiService.get("case/" + item.CaseID + "/related"),
                    apiService.get("case/" + item.CaseID + "/detail").then(res => {
                        const detailPromises = [
                            preCacheFiles(res.data),
                            apiService.get("client/" + res.data.Branch.Client.ClientID + "/detail"),
                            apiService.get("client/branch/" + res.data.Branch.BranchID + "/detail"),
                            apiService.get("client/branch/" + res.data.Branch.BranchID + "/requestors?max=10000"),
                            apiService.get("client/requestor/" + res.data.Branch.Requester.UserAccountID + "/detail"),
                            apiService.get("insured/" + res.data.InsuredClientID + "/detail")
                        ];

                        if (res.data.Branch.Client.Subject.Image) {
                            detailPromises.push(axiosIns.get(res.data.Branch.Client.Subject.Image)
                                .catch(error => syncErrors.push(error))
                            );
                        }

                        return Promise.all(detailPromises);
                    })
                );
            }
            if (item.ActionID) {
                itemPromises.push(
                    preCacheAction(item.ActionID)
                );
            }
        }

        return itemPromises;
    });

    Promise.all(promises)
        .then(() => {
            callBack(true, syncErrors.length)
        })
        .catch(error => {
            console.error(error);
            callBack(false, syncErrors.length)
        })
        .finally(() => {
            syncErrors = []
        })

}

export const clearCache = async () => {
    await caches.delete('case-cache');
    await indexedDB.deleteDatabase("workbox-expiration");
    await indexedDB.deleteDatabase("workbox-background-sync");
}

const preCacheAction = (ActionID) => {
    apiService.get("distances/user-action?actionId=" + ActionID)
    apiService.get("action/" + ActionID + "/detail").then(res => {
        if (res.data) {
            haveAssignmentsInAction(res.data)
        }
        if (res.data.Activities) {
            const activityPromises = res.data.Activities.map(activity => {
                const activityPromises = [
                    apiService.get("activity/" + activity.ActivityID + "/detail")
                        .then(res => {
                            apiService.get("user/" + res.data.UserID + "/vehicles")
                        }),
                    apiService.get("activity/" + activity.ActivityID + "/media-library").then(images => {
                        return Promise.all(images.data.map(image => axiosIns.get("/api/file/stream/" + image.FileID)
                            .catch(error => syncErrors.push(error))
                        ));
                    }),
                    apiService.get("activity/" + activity.ActivityID + "/videos").then(videos => {
                        return Promise.all(videos.data.map(video => axiosIns.get("https://api.nimbushd.com/Imaging/Thumbnail.ashx?id=" + video.VideoID)
                            .catch(error => syncErrors.push(error))
                        ));
                    }),
                    apiService.get("file/nimbus-downloader-key/" + activity.ActivityID),
                    apiService.get('activity/' + activity.ActivityID + '/expenses').then(result => {
                        return Promise.all(result.data.map(exp => apiService.get("activity/expense/" + exp.ExpenseID + "/detail")));
                    })
                ];

                return Promise.all(activityPromises);
            });

            return Promise.all(activityPromises);
        }
    })
}

export const haveAssignmentsInAction = (item) => {
    const uid = localStorage.getItem('UID')
    const isEmpty = !item.Activities.find(i => String(i.CreatedByUserId) === uid)
    if (isEmpty) {
        apiService.get("user/" + uid + "/vehicles").then(veh => {
            UserOwnVehicles = veh.data.map(i => ({
                value: i.id,
                unitNumber: i.id
            }));
            createEmptyActivity(item)
        })
    }
}

const createEmptyActivity = (item) => {
    const actionDate = item.ScheduledDate.split('T')[0]
    const VehicleID = vehicles.find(i => i.unitNumber === "N/A").value
    let postData = {
        "ActivityType": item.ActionTypeName,
        "CaseID": item.CaseID,
        "ActionID": item.ActionID,
        "SubjectID": item.SubjectID,
        "BeginTime": locationDeviation(actionDate, item.TimeWindowStart),
        "OnSiteArrivalTime": locationDeviation(actionDate, item.TimeWindowStart),
        "OnSiteDepartureTime": locationDeviation(actionDate, item.TimeWindowEnd, true),
        "EndTime": locationDeviation(actionDate, item.TimeWindowEnd, true),
        "VehicleID": VehicleID,
    };

    if (item.IsTravelRequired) {
        postData = {
            ...postData,
            "VehicleID": vehicles.find(i => i.unitNumber === "POV").value,
            "UserOwnVehicleID": UserOwnVehicles.length ? UserOwnVehicles[0].value : null
        }
    }

    apiService
        .post("activity/create", postData)
        .then(res => {
            if (res) {
                preCacheAction(postData.ActionID)
            }
        })
}

const locationDeviation = (date, time, endTime = false) => {
    if (!time) {
        time = endTime ? new Date(Date.now() + 60000).toISOString().substr(11, 5) : new Date().toISOString().substr(11, 5);
    }
    const totalDate = new Date(`${date}T${time}`);
    return new Date(totalDate.getTime() - (totalDate.getTimezoneOffset() * 60000)).toISOString();
};

export async function preCacheFiles(item) {
    const maxSize = 1024 * 1024 * 20
    for (let i in item.Files)
    {
        if (i.UserTypePermissions
            && i.UserTypePermissions.indexOf("Investigator") !== -1
            && i.FileSize && i.FileSize <= maxSize) {
            await axiosIns.get(i.URL).catch(error => syncErrors.push(error))
        }
    };
}