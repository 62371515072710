<template>
  <b-row class="pb-2 pt-2">
    <b-col>
      <b-row>
        <b-col md="3">
          <b-form-group
            label="Date of Injury / Loss"
            label-for="dateInjury"
          >
            <b-form-input
              id="dateInjury"
              v-model="InjuryDate"
              type="date"
              placeholder=""
              name="dateInjury"
              :readonly="readOnly"
            />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            label="Description of Injury / Loss"
            label-for="descriptionInjury"
          >
            <div
              class="collapse-textarea"
              :class="{expanded: !isExpandedInjuryDesc}"
            >
              <textarea
                v-model="InjuryDesc"
                class="expandable-textarea"
                :class="[isExpandedInjuryDesc ? 'not-expanded' : 'expanded']"
                contentEditable
                :readonly="readOnly"
              />
              <div @click="isExpandedInjuryDesc = !isExpandedInjuryDesc">
                <span
                  class="expand-arrow mt-n1"
                  :class="[isExpandedInjuryDesc ? 'not-expanded' : 'expanded']"
                >&#187;</span>
              </div>
            </div>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row class="mb-1">
        <b-col md="3">
          <p class="text-vertical-center">
            Medical Restrictions
          </p>
        </b-col>
        <b-col>
          <div
            class="collapse-textarea"
            :class="{expanded: !isExpandedMedicalRestrictions}"
          >
            <textarea
              v-model="MedicalRestrictions"
              class="expandable-textarea"
              :class="[isExpandedMedicalRestrictions ? 'not-expanded' : 'expanded']"
              contentEditable
              :readonly="readOnly"
            />
            <div @click="isExpandedMedicalRestrictions = !isExpandedMedicalRestrictions">
              <span
                class="expand-arrow mt-n1"
                :class="[isExpandedMedicalRestrictions ? 'not-expanded' : 'expanded']"
              >&#187;</span>
            </div>
          </div>
        </b-col>
      </b-row>


      <b-row class="mb-1">
        <b-col md="3">
          <p class="text-vertical-center">
            Red Flags
          </p>
        </b-col>
        <b-col>
          <div
            class="collapse-textarea"
            :class="{expanded: !isExpandedRedFlags}"
          >
            <textarea
              v-model="RedFlags"
              class="expandable-textarea"
              :class="[isExpandedRedFlags ? 'not-expanded' : 'expanded']"
              contentEditable
              :readonly="readOnly"
            />
            <div @click="isExpandedRedFlags = !isExpandedRedFlags">
              <span
                class="expand-arrow mt-n1"
                :class="[isExpandedRedFlags ? 'not-expanded' : 'expanded']"
              >&#187;</span>
            </div>
          </div>
        </b-col>
      </b-row>

      <b-row class="mb-1">
        <b-col md="3">
          <p class="text-vertical-center">
            Video Summary
          </p>
        </b-col>
        <b-col>
          <div
            class="collapse-textarea"
            :class="{expanded: !isExpandedVideoSummary}"
          >
            <textarea
              v-model="VideoSummary"
              class="expandable-textarea"
              :class="[isExpandedVideoSummary ? 'not-expanded' : 'expanded']"
              contentEditable
              :readonly="readOnly"
            />
            <div @click="isExpandedVideoSummary = !isExpandedVideoSummary">
              <span
                class="expand-arrow mt-n1"
                :class="[isExpandedVideoSummary ? 'not-expanded' : 'expanded']"
              >&#187;</span>
            </div>
          </div>
        </b-col>
      </b-row>

      <b-row class="mb-1">
        <b-col md="3">
          <p>Employment Info / Current Work Status</p>
        </b-col>
        <b-col>
          <div
            class="collapse-textarea"
            :class="{expanded: !isExpandedWorkStatus}"
          >
            <textarea
              v-model="EmploymentInfoWorkStatus"
              class="expandable-textarea"
              :class="[isExpandedWorkStatus ? 'not-expanded' : 'expanded']"
              contentEditable
              :readonly="readOnly"
            />
            <div @click="isExpandedWorkStatus = !isExpandedWorkStatus">
              <span
                class="expand-arrow mt-n1"
                :class="[isExpandedWorkStatus ? 'not-expanded' : 'expanded']"
              >&#187;</span>
            </div>
          </div>
        </b-col>
      </b-row>


      <b-row>
        <b-col md="3">
          <b-form-group
            label="Represented by Attorney"
            label-for="representedAttorney"
          >
            <b-form-checkbox
              id="representedAttorney"
              v-model="HasAttorneyRepresentation"
              name="representedAttorney"
              :disabled="readOnly"
            >
              Represented by Attorney
            </b-form-checkbox>
            <b-form-checkbox
              id="scheduledAppointments"
              v-model="ScheduledAppts"
              name="scheduledAppointments"
              :disabled="readOnly"
            >
              Scheduled Appointments
            </b-form-checkbox>
          </b-form-group>
        </b-col>
        <b-col
          v-if="HasAttorneyRepresentation"
          md="1"
        >
          <p class="text-center text-vertical-center">
            Who
          </p>
        </b-col>
        <b-col v-if="HasAttorneyRepresentation">
          <b-form-input
            id="who"
            v-model="AttorneyRepInfo"
            placeholder=""
            name="who"
            :readonly="readOnly"
          />
        </b-col>
      </b-row>
      <b-row v-if="ScheduledAppts">
        <b-col md="9">
          <div class="white-bg-container border-gray">
            <Appointment
              v-for="(appointment, index) of Appointments"
              :key="index"
              :index="index"
              class="mb-1"
              @updateAppointment="updateAppointment"
            />
          </div>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import {BCol, BFormCheckbox, BFormGroup, BFormInput, BRow} from "bootstrap-vue";
import Appointment from "@/views/cases/components/Appointment";
import {mapGetters} from "vuex";

export default {
  components: {
    Appointment,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
  },
  data() {
    return {
      ClaimDetail: {},
      InjuryDate: "",
      InjuryDesc: "",
      MedicalRestrictions: "",
      RedFlags: "",
      VideoSummary: "",
      EmploymentInfoWorkStatus: "",
      HasAttorneyRepresentation: false,
      ScheduledAppts: false,
      AttorneyRepInfo: "",
      Appointments: [{
        ScheduledApptWhen: "",
        ScheduledApptWhere: "",
      }],

      readOnly: false,

      isExpandedInjuryDesc: false,
      isExpandedMedicalRestrictions: false,
      isExpandedRedFlags: false,
      isExpandedVideoSummary: false,
      isExpandedWorkStatus: false,
    }
  },
  computed: {
    ...mapGetters({
      getClaimDetail: "cases/getClaimDetail",
      getIsReadOnly: "cases/getIsReadOnly",
      getAllCaseTabOpen: "cases/getAllCaseTabOpen",
    }),
    getClaimDetailData() {
      return this.getClaimDetail
    },
    isReadOnly() {
      return this.getIsReadOnly;
    },
  },
  watch: {
    getAllCaseTabOpen: {
      immediate: true,
      handler(value) {
        const val = !!value
        this.isExpandedInjuryDesc = val
        this.isExpandedMedicalRestrictions = val
        this.isExpandedRedFlags = val
        this.isExpandedWorkStatus = val
      }
    },
    getClaimDetailData(val) {
      this.fillClaimDetail(val);
    },
    isReadOnly(val) {
      this.readOnly = val
    },
    InjuryDate(val) {this.ClaimDetail = {...this.ClaimDetail, InjuryDate: val}},
    InjuryDesc(val) {this.ClaimDetail = {...this.ClaimDetail, InjuryDesc: val}},
    MedicalRestrictions(val) {this.ClaimDetail = {...this.ClaimDetail, MedicalRestrictions: val}},
    RedFlags(val) {this.ClaimDetail = {...this.ClaimDetail, RedFlags: val}},
    VideoSummary(val) {this.ClaimDetail = {...this.ClaimDetail, VideoSummary: val}},
    EmploymentInfoWorkStatus(val) {this.ClaimDetail = {...this.ClaimDetail, EmploymentInfoWorkStatus: val}},
    HasAttorneyRepresentation(val) {this.ClaimDetail = {...this.ClaimDetail, HasAttorneyRepresentation: val}},
    ScheduledAppts(val) {this.ClaimDetail = {...this.ClaimDetail, ScheduledAppts: val}},
    AttorneyRepInfo(val) {this.ClaimDetail = {...this.ClaimDetail, AttorneyRepInfo: val}},
    Appointments(val) {this.ClaimDetail = {...this.ClaimDetail, Appointments: val}},

    ClaimDetail() {
      this.$emit("changeClaimDetail", this.ClaimDetail);
    }
  },
  async created() {
    this.readOnly = this.getIsReadOnly;
    try {
      this.fillClaimDetail(this.getClaimDetail);
    } catch (err) {
      this.error = err
    }
  },
  methods: {
    updateAppointment(val, index) {
      this.Appointments.splice(index, 1, val);
    },
    fillClaimDetail(val) {
      this.InjuryDate = val.InjuryDate.substr(0, 10);
      this.InjuryDesc = val.InjuryDesc;
      this.MedicalRestrictions = val.MedicalRestrictions;
      this.RedFlags = val.RedFlags;
      this.VideoSummary = val.VideoSummary;
      this.EmploymentInfoWorkStatus = val.EmploymentInfoWorkStatus;
      this.HasAttorneyRepresentation = val.HasAttorneyRepresentation;
      this.ScheduledAppts = val.ScheduledAppts;
      this.AttorneyRepInfo = val.AttorneyRepInfo;
      this.Appointments = val.Appointments;
    }
  }
}
</script>

<style scoped>
.border-gray {
  border: 1px solid #c7c7cc !important
}
.text-vertical-center {
  margin-top: 0.5rem;
}

p {
  font-size: 0.857rem;
}
</style>