export const  weekdayText = [" Mon ", " Tue ", " Wed ", " Thu ", " Fri ", " Sat ", " Sun "];

export default class SchedulesService {
    getScheduleDate(scheduleDate, isStart, isNew = false) {
        if (isStart) {
            let date = new Date(scheduleDate);
            return this.datetimeFormatter(date, isNew)
        } else {
            let date = new Date(scheduleDate);
            date.setDate(date.getDate() + 1);
            return this.datetimeFormatter(date)
        }
    };

    dateFormat(val) {
        if (val) {
            const date = new Date(val);
            const options = {month: '2-digit', day: '2-digit', year: 'numeric'}
            if (date) return date.toLocaleDateString("en-US", options);
        }
        if (val) return val.substr(0, 10);
        return val
    };

    dateFormatter(dateObj) {
        let month = dateObj.getMonth() + 1;
        if (month < 10) {
            month = "0" + month;
        }
        let date = dateObj.getDate();
        if (date < 10) {
            date = "0" + date;
        }
        const day = dateObj.getDay();
        const dayText = weekdayText[day ? day - 1 : 6];
        return dayText + " " + month + "/" + date;
    };

    dateFormatToSort(dateObj, n = 0) {
        if (dateObj) {
            dateObj = new Date(dateObj);
            if (n) {
                dateObj = this.addDays(dateObj, n - 2)
            }
            let year = dateObj.getFullYear();
            let month = dateObj.getMonth() + 1;
            if (month < 10) {
                month = "0" + month;
            }
            let date = dateObj.getDate();
            if (date < 10) {
                date = "0" + date;
            }
            return year + "/" + month + "/" + date
        }
        return null
    };

    datetimeFormatter(dateObj, isNew = false) {
        let date = dateObj.getDate();
        if (isNew) {
            let day = dateObj.getDay();
            if (day) {
                dateObj.setDate(date + (5 - day))
                date = dateObj.getDate()
            }
        }
        if (date < 10) {
            date = "0" + date;
        }
        let month = dateObj.getMonth() + 1;
        if (month < 10) {
            month = "0" + month;
        }
        let year = dateObj.getFullYear();
        let hours = dateObj.getHours();
        if (hours < 10) {
            hours = "0" + hours;
        }
        let minutes = dateObj.getMinutes();
        if (minutes < 10) {
            minutes = "0" + minutes;
        }
        return year + "/" + month + "/" + date + " " + hours + ":" + minutes;
    };

    getDateDiff(date1, date2) {
        const diffTime = Math.abs(date2 - date1) + 1;
        return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    };

    getMinutesDiff(date1, date2) {
        const diffTime = Math.abs(date2 - date1);
        return Math.ceil(diffTime / (1000 * 60));
    }

    addDays(dateObj, n) {
        dateObj.setTime(dateObj.getTime() + (n + 1) * 60 * 60 * 24 * 1000);
        return dateObj;
    };

    addMinutes(dateObj, n) {
        dateObj.setTime(dateObj.getTime() + n * 60 * 1000);
        return dateObj;
    };

    dateParser(val) {
        const date = new Date(val);
        const d = date.getDate().toString();
        const m = (date.getMonth() + 1).toString();
        const y = date.getFullYear();
        return y + "-" + (m.length === 1 ? "0" + m : m) + "-" + (d.length === 1 ? "0" + d : d);
    };

    byField(field, desc = true) {
        if (desc) return (a, b) => a[field] > b[field] ? 1 : -1;
        return (a, b) => a[field] < b[field] ? -1 : 1;
    };
}