<template>
  <div>
    <div class="row mb-1">
      <div class="col-6">
        <!-- add button -->
        <div>
          <div class="col-4 pr-0 pl-0 D-flex justify-space-arround">
            <b-button
              block
              variant="primary"
              style="
              justify-content: space-around;
              display: flex;
              border-radius: 0;
              width: max-content;
            "
              @click="$router.push('/cases/add-journal-entry')"
            >
              <span>+ Add Journal Entry</span>
            </b-button>
          </div>
        </div>
      </div>
    </div>

    <!-- table -->

    <b-col class="border-top-dark min-height-10 rounded-sm">
      <b-row
        class="bg-light border-left-dark border-right-dark height-50 d-flex align-items-center"
        :class="{'border-bottom-dark': !rows.length}"
      >
        <b-col md="3">
          Category
        </b-col>
        <b-col md="2">
          Journal Entry
        </b-col>
        <b-col md="2">
          Creator
        </b-col>
        <b-col md="2">
          Date / Time
        </b-col>
        <b-col
          md="2"
          class="text-nowrap"
        >
          Date Sent
        </b-col>
        <b-col>Actions</b-col>
      </b-row>
      <b-row
        v-for="(item, index) of rows"
        :key="index"
        class="border-left-dark border-right-dark d-flex align-items-center"
        :class="{'border-bottom-dark': index + 1 === rows.length}"
      >
        <b-col>
          <b-row
            class="pt-1 border-top"
            :class="{'primary': index === isOpen}"
          >
            <b-col md="3">
              <span
                class="p-1"
                @click="toggleRow(index)"
              >
                <img
                  v-if="index === isOpen"
                  src="../../../assets/images/icons/VectorOpen.png"
                  alt="vector"
                >
                <img
                  v-else
                  src="../../../assets/images/icons/VectorClose.png"
                  alt="vector"
                >
              </span>
              <span class="text-nowrap">{{ item.Category }}</span>
              <div
                v-if="item.ApprovedBy"
                class="pl-3"
              >
                Approved {{ item.ApprovedOnDate | dateFormat }}<br> by {{ item.ApprovedBy }}
              </div>
              <div
                v-if="item.RejectedBy"
                class="pl-3"
              >
                Rejected {{ item.RejectedOnDate | dateFormat }}<br> by {{ item.RejectedBy }}
              </div>
              <div
                v-if="!item.ApprovedBy && !item.RejectedBy"
                class="pl-3 not-approved"
              >
                Not Approved
              </div>
              <span
                v-if="item.FileName"
                class="float-right blank-img pl-1 cursor-pointer"
                @click="downloadFile(item)"
              >
                <img
                  v-if="index === isOpen"
                  src="../../../assets/images/icons/blanck.png"
                  alt="vector"
                >
                <img
                  v-else
                  src="../../../assets/images/icons/blanck-dark.png"
                  alt="vector"
                >
              </span>
            </b-col>
            <b-col md="2">
              <div class="clip">
                {{ item.Title }}
              </div>
            </b-col>
            <b-col md="2">
              <div class="clip">
                {{ item.CreatedBy }}
              </div>
            </b-col>
            <b-col md="2">
              <div class="text-nowrap">{{ item.CreatedDate | dateFormat }}</div>
              <div class="text-nowrap">{{ item.CreatedDate | timeFormat }}</div>
            </b-col>
            <b-col md="2">
              <div class="clip">
                {{ item.EMailedOnDate | dateFormat| isEmpty }}
              </div>
            </b-col>
            <b-col md="1">
              <span>
                <b-dropdown
                  variant="link"
                  toggle-class="text-decoration-none"
                  no-caret
                >
                  <template v-slot:button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="16"
                      class="text-body align-middle mr-25"
                    />
                  </template>
                  <b-dropdown-item v-if="!getCase.IsLegalHold">
                    <router-link
                      :to="'/cases/view-journal-entry/' + item.JournalEntryID"
                    >{{ "View Entry" }}</router-link>
                  </b-dropdown-item>
                  <b-dropdown-item v-if="!getCase.IsLegalHold">
                    <router-link
                      :to="'/cases/edit-journal-entry/' + item.JournalEntryID"
                    >{{ "Edit Entry" }}</router-link>
                  </b-dropdown-item>
                  <b-dropdown-item @click="approve(item.JournalEntryID)">
                    <span>Approve Now</span>
                  </b-dropdown-item>
                  <b-dropdown-item @click="reject(item.JournalEntryID)">
                    <span>Reject Now</span>
                  </b-dropdown-item>
                </b-dropdown>
              </span>
            </b-col>
          </b-row>
          <b-row
            v-if="index === isOpen"
            class="p-2"
          >
            <b-col>
              <b-row class="mb-0">
                <b-col md="3">
                  <span>Visible to: {{ item.PermissionMask.replaceAll(';',', ') | permissionMaskFormat }}</span>
                </b-col>
                <b-col>
                  <b-row
                    v-for="(action, actionIndex) of item.ActionIds"
                    :key="actionIndex"
                  >
                    <b-col>
                      <router-link :to="'/cases/edit-action/' + action">
                        <span class="text-nowrap">{{ fillAction(action) }}</span>
                      </router-link>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
              <b-row class="mb-0">
                <span
                  class="cursor-pointer btn-link"
                  @click="downloadFile(item)"
                >{{ item.FileName }}</span>
              </b-row>
              <b-row class="mb-0">
                <b-col md="3">
                  <span>Last Sent:  {{ item.EMailedOnDate | dateFormat }} to {{ item.EMailedTo }}</span>
                </b-col>
                <b-col v-if="item.Files && item.Files.length">
                  <b-row>
                    <b-col md="0">
                      <feather-icon
                        icon="FileIcon"
                        size="20"
                        class="text-body align-middle ml-1"
                      />
                    </b-col>
                    <b-col>
                      <b-row
                        v-for="(file, ind) of item.Files"
                        :key="ind"
                      >
                        <b-col
                          md="2"
                          class="cursor-pointer btn-link"
                          @click="downloadAtach(file)"
                        >
                          <span>{{ file.Name + ";" }}</span>
                        </b-col>
                      </b-row>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
              <b-row class="mb-0">
                <b-col md="3">
                  <span>Approved {{ item.ApprovedOnDate | dateFormat }} by {{ item.ApprovedBy }}</span>
                </b-col>
              </b-row>
              <b-row class="mb-0">
                <b-col md="3">
                  <span>Modified {{ item.LastModifiedDate | dateFormat }} by {{ item.ModifiedBy }}</span>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-col>
  </div>
</template>

<script>
import {BButton, BCol, BDropdown, BDropdownItem, BRow} from "bootstrap-vue";
import "vue-good-table/dist/vue-good-table.css";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import store from "@/store/index";
import APIService from "@core/utils/APIService";
import {mapGetters, mapMutations} from "vuex";

const apiService = new APIService();


export default {
  components: {
    BRow,
    BCol,
    BButton,
    BDropdown,
    BDropdownItem,
  },
  filters: {
    dateFormat(val) {
      if (val) {
        const date = new Date(val);
        const options = {month: '2-digit', day: '2-digit', year: 'numeric'}
        if (date) return date.toLocaleDateString("en-US", options);
      }
      if (val) return val.substr(0, 10);
      return val
    },
    timeFormat(val) {
      if (val) {
        const date = new Date(val);
        const options = {hour: "2-digit", minute: "2-digit"}
        if (date) return date.toLocaleTimeString("en-US", options);
      }
      return val
    },
    isEmpty(val) {
      if (!val) {
        return "Never"
      }
      return val;
    },
    permissionMaskFormat(val) {
      return val.substr(0, val.length - 2)
    }
  },
  data() {
    return {
      dir: false,
      rows: [],
      isOpen: -1,
      ActionsToIncludeOption: [],
      status: [
        {
          1: "Active",
          2: "Disabled",
        },
        {
          1: "light-primary",
          2: "light-danger",
        },
      ],
    };
  },

  computed: {
    ...mapGetters({
      getCase: "cases/getCase",
    }),

    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Current: "light-primary",
        Professional: "light-success",
        Rejected: "light-danger",
        Resigned: "light-warning",
        Applied: "light-info",
        /* eslint-enable key-spacing */
      };

      return (status) => statusColor[status];
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true;
        return this.dir;
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false;
      return this.dir;
    },
  },

  watch: {},

  mounted() {
    this.loadActions();
    this.loadActionLogs();
  },

  methods: {
    ...mapMutations({}),

    loadActionLogs() {
      this.rows = [];
      apiService
          .get("journal/entries/" + this.getCase.CaseID)
          .then((response) => {
            this.rows = response.data.sort((a,b) => (a.CreatedDate < b.CreatedDate) ? 1 : ((b.CreatedDate < a.CreatedDate) ? -1 : 0));
          });
    },
    loadActions() {
      apiService
          .get("actions/short-info/case/" + this.getCase.CaseID)
          .then((res) => {
            this.ActionsToIncludeOption = res.data.map(i => ({title: i.ActionFileNumber + " - " + i.ActionTypeName, id: i.ActionID}));
          })
    },

    fillAction(ID) {
      return this.ActionsToIncludeOption.filter(i => i.id === ID)[0].title;
    },

    toggleRow(index) {
      this.isOpen = this.isOpen === index ? -1 : index;
    },

    showToast(variant, position, timeout, data) {
      this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: "Notification",
              icon: "InfoIcon",
              text: data,
              variant,
            },
          },
          {
            position,
            timeout,
          }
      );
    },
    approve(id) {
      apiService
          .post("journal/entry/" + id + "/approve")
          .then(res => {
            this.loadActionLogs();
          })
    },
    reject(id) {
      apiService
          .post("journal/entry/" + id + "/reject")
          .then(res => {
            if (res) {
              this.showToast("success", "top-center", 4000, "Journal entry rejected and removed.");
              this.loadActionLogs();
            }
          })
    },
    downloadAtach(file) {
      this.downloadFile({FileID: file.ID, FileName: file.Name})
    },
    downloadFile(file) {
      const link = document.createElement('a');
      link.href = '/api/file/stream/' + file.FileID;
      link.setAttribute('download', file.FileName);
      document.body.appendChild(link);
      link.click();
    },
  },
};
</script>

<style>
ul.dropdown-menu li {
  height: 2rem;
}
.primary {
  background: #476dae;
  color: white;
}
.primary circle {
  color: white;
}
.not-approved {
  color: red;
}
.blank-img {
  position: relative;
  top: -50%;
}
.clip {
  white-space: pre-wrap !important;
  max-height: 5rem !important;
  overflow: hidden;
  text-overflow: ellipsis;
}
.disabled,
.disabled span {
  color: #9cb594;
}
</style>
