<template>
  <div class="navbar-container d-flex content align-items-center">

    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div
      v-if="getShowMainSearch && onLine"
      class="bookmark-wrapper align-items-center d-none d-lg-flex mr-2"
    >
      <div
        class="mr-2"
        style="width: 680px; max-width:680px"
      >
        <v-select
          v-model="mainSearchCategory"
          label="text"
          :options="options"
          placeholder="Search Category"
          style="background: white; border-radius: 0.357rem"
        />
      </div>
      <b-input-group class="input-group-merge">
        <b-input-group-prepend is-text>
          <feather-icon icon="SearchIcon" />
        </b-input-group-prepend>
        <b-form-input
          v-model="mainSearchItem"
          placeholder="Search"
          @blur.native="handleMainSearchItem"
          @keypress.enter.prevent="handleMainSearchItem"
        />
      </b-input-group>
    </div>

    <b-navbar-nav class="nav align-items-center ml-auto">
      <NetworkStatus/>
      <div
        v-if="currentScopes.indexOf('st2.globalsearch') !== -1 && onLine"
        class="mr-2"
        @click="toggleSearch()"
      >
        <feather-icon
          icon="SearchIcon"
          size="21"
          class="navbar-icon-white cursor-pointer"
        />
      </div>
      <div
        v-else
        class="mr-2"
        style="width: 2.5rem"
      />
      <div
        class="mr-0"
        @click="toggleNotification()"
      >
        <feather-icon
          icon="BellIcon"
          size="21"
          class="navbar-icon-white cursor-pointer"
        />
        <div
          v-if="Notifications.length"
          class="notification-counter"
        >
          {{ Notifications.length }}
        </div>
      </div>


      <b-nav-item-dropdown
        v-if="authUser && onLine"
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user width-30-per"
      >
        <template #button-content>
          <div class="d-sm-flex d-none user-nav">
            <p class="user-name font-weight-bolder mb-0">
              <!-- John Doe -->
            </p>
            <!-- <span class="user-status">Admin</span> -->
          </div>
          <feather-icon
            icon="UserIcon"
            size="21"
            class="navbar-icon-white"
          />
        </template>

        <b-dropdown-item
          link-class="d-flex align-items-center"
          @click="viewProfile()"
        >
          <feather-icon
            size="16"
            icon="UserIcon"
            class="mr-50"
          />
          <span> View Profile </span>
        </b-dropdown-item>
        <b-dropdown-item link-class="d-flex align-items-center">
          <feather-icon
            size="16"
            icon="FileIcon"
            class="mr-50"
          />
          <span> Contact Us </span>
        </b-dropdown-item>
        <b-dropdown-item
          link-class="d-flex align-items-center"
          @click="signOut()"
        >
          <feather-icon
            size="16"
            icon="LogOutIcon"
            class="mr-50"
          />
          <span> Logout </span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
      <dark-Toggler class=" d-lg-block" />
    </b-navbar-nav>
    <div
      v-if="showNotification"
      class="notification-bar"
    >
      <b-row class="notification-dismiss-all m-0">
        <b-col>
          <span
            class="cursor-pointer"
            @click="dismissAll"
          >
            Dismiss All
          </span>
          <span
            class="close-btn float-right"
            @click="showNotification = false"
          >
            +
          </span>
        </b-col>
      </b-row>
      <b-row
        v-for="Notification in Notifications"
        :key="Notification.NotificationID"
        class="m-0"
      >
        <b-col class="notification-item">
          <span>
            <span class="bell-icon">
              <feather-icon
                icon="BellIcon"
                size="21"
                class="navbar-icon-white"
              />
            </span>
            <span class="title-notification">
              {{ Notification.NotificationTitle }}
            </span>
            <span class="float-right notification-date">
              <span class="pr-1">
                {{ Notification.Timestamp | dateFormat }}
              </span>
              <span
                class="close-btn"
                @click="closeNotificationToId(Notification.NotificationID)"
              >
                +
              </span>
            </span>
          </span>

          <div
            v-if="Notification.OnClickURL"
            class="pl-3 text-notification"
          >
            <router-link
              :to="Notification.OnClickURL"
              target="_blank"
            >
              {{ Notification.NotificationText }}
            </router-link>
          </div>

          <div
            v-else
            class="pl-3 text-notification"
          >
            {{ Notification.NotificationText }}
          </div>

          <div
            v-if="Notification.URLReference"
            class="pl-3 text-notification"
          >
            <a
              :href="Notification.URLReference"
              target="_blank"
            >
              Additional info…
            </a>
          </div>

        </b-col>
      </b-row>
    </div>
    <b-modal
      id="modal-session-expiring"
      ok-variant="danger"
      ok-title="Logout"
      cancel-title="Keep Working"
      modal-class="modal-danger"
      hide-header-close
      centered
      title="Session Expiring"
      @ok="signOut()"
      @hide="onHide"
    >
      <div>
        Your session is about to expire due to inactivity.  {{ countdown }}
      </div>
    </b-modal>

    <b-modal
      id="modal-acknowledge"
      ok-variant="primary"
      ok-title="Acknowledge"
      modal-class="modal-danger"
      hide-header-close
      ok-only
      centered
      :title="Acknowledges.length && Acknowledges[0].NotificationTitle || ''"
      @ok="AcknowledgeNotification()"
      @hide="onHide"
    >
      <div
        v-if="Acknowledges.length && Acknowledges[0].OnClickURL"
        class="pl-3 text-notification"
      >
        <router-link
          :to="Acknowledges.length && Acknowledges[0].OnClickURL"
          target="_blank"
        >
          {{ Acknowledges.length && Acknowledges[0].NotificationText }}
        </router-link>
      </div>

      <div
        v-else
        class="pl-3 text-notification"
      >
        {{ Acknowledges.length && Acknowledges[0].NotificationText }}
      </div>
      <div
        v-if="Acknowledges.length && Acknowledges[0].URLReference"
        class="pl-3 text-notification"
      >
        <a
          :href="Acknowledges.length && Acknowledges[0].URLReference"
          target="_blank"
        >
          Additional info…
        </a>
      </div>
    </b-modal>

  </div>
</template>

<script>
import {
  BLink, BNavbarNav, BNavItemDropdown, BDropdownItem,
  BInputGroup, BFormInput, BInputGroupPrepend, BBadge, BRow, BCol,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'
import 'vue-select/dist/vue-select.css'
import {mapGetters, mapMutations} from "vuex";
import store from "@/store";
import axios from 'axios';
import NetworkStatus from "@/layouts/components/NetworkStatus";

const CATEGORIES = ['Internal Number', 'Claim Number', 'Subject', 'Requestor']
export default {
  components: {
    NetworkStatus,
    vSelect,
    BRow,
    BCol,
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BInputGroup,
    BFormInput,
    BInputGroupPrepend,
    // eslint-disable-next-line vue/no-unused-components
    BBadge,
    // Navbar Components
    DarkToggler,
  },
  filters: {
    dateFormat(val) {
      const inputVal = new Date(val)
      const currentTime = new Date();
      const inputTime = new Date(inputVal.getTime() - (inputVal.getTimezoneOffset() * 60000));

      const differentMs = (currentTime - inputTime);
      const different = Math.floor(differentMs / 60000);

      const day = Math.floor(different / 1440);
      const hours = Math.floor((different - day * 1440) / 60);
      const minutes = Math.floor((different - hours * 60));

      const output = day ? day + (day === 1 ? " day " : " days ") : hours ? hours + " hours " : minutes + " minutes "

      return output + "ago"
    }
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      currentScopes: [],
      mainSearchCategory: '',
      mainSearchItem: '',
      authUser: Boolean,
      selected: [],
      searchItem: "",
      options: [
        ...CATEGORIES.map(category => ({ value: category, text: category })),
      ],
      pills: [
      ],
      Notifications: [],
      showNotification: false,

      UserType: "",
      countdown: "",
      refreshInterval: null,

      onLine: navigator.onLine,

      Acknowledges: [],
    }
  },
  computed: {
    ...mapGetters({
      getMainSearchCategory: "search/getMainSearchCategory",
      getMainSearchItem: "search/getMainSearchItem",
      getShowMainSearch: "search/getShowMainSearch",
      getNotification: "notification/getNotification",
      getCurrentScopes: "scopes/getCurrentScopes",
      getExpires: "auth/getExpires",
      getBackendActivity: "users/getBackendActivity",
      getOnlineStatus: "offline/getOnlineStatus",
    }),
    hasAccess() {
      return this.$route.meta.isPublic || this.authUser
    },
  },
  watch: {
    getOnlineStatus(val) {
      this.onLine = val;
    },
    $route(val) {
      this.checkPermissionSet(val.path)
    },
    mainSearchCategory(val) {
      this.mainSearchItem = null;
      this.setMainSearchCategory(val.value)
    },
    getBackendActivity(val, oldVal) {
      if (val && val !== oldVal) {
        this.loopRefreshToken();
      }
    },
    mainSearchItem(val) {
      this.setMainSearchItem(val)
    },
    getNotification() {
      this.Notifications = this.getNotification;
    },
    authUser() {
      this.currentScopes = this.getCurrentScopes
    },
    currentScopes() {
      if (this.currentScopes.indexOf('st2.globalsearch') !== -1) {
        this.setShowMainSearch(true);
      }
    },
    getExpires(val) {
      if (val <= 300) {
        this.showModal(val);
      }
    },
    Acknowledges(val) {
      if (val && this.Acknowledges.length) this.showAcknowledgesNotifications();
    }
  },
  async mounted() {
    this.UserType = localStorage.getItem("userType");
    this.updateNotifications();
    setTimeout(async () => {
      this.authUser = await this.$oidc.isLoggedIn();
      this.getFreshNotification();
      this.Notifications = this.getNotification;
    }, 1500)
    if (this.getMainSearchCategory) {
      this.mainSearchCategory = { value: this.getMainSearchCategory, text: this.getMainSearchCategory };
      this.mainSearchItem = this.getMainSearchItem;
    }
    this.checkPermissionSet(window.location.pathname)
  },

  async updated() {
    this.authUser = await this.$oidc.isLoggedIn()
  },

  beforeDestroy() {
      if (this.refreshInterval) clearInterval(this.refreshInterval);
  },

  methods: {
    ...mapMutations({
      setMainSearchCategory: "search/setMainSearchCategory",
      setMainSearchItem: "search/setMainSearchItem",
      setShowMainSearch: "search/setShowMainSearch",
      setSessionEnds: "auth/setSessionEnds",
      setExpires: "auth/setExpires",
      setBackendActivity: "users/setBackendActivity",
    }),

    loopRefreshToken() {
      this.refreshToken()
      const _this = this;
      this.refreshInterval = setInterval(() => {
        _this.setBackendActivity(false)
      }, 15 * 60 * 1000)
    },

    userName() {
      return localStorage.getItem('userName')
    },

    checkPermissionSet(val) {
      const scope = this.getCurrentScopes;
      if (val.indexOf("admin") !== -1 && scope.indexOf("st2.showadminmenu") === -1) this.$router.push('/home');
      if (val.indexOf("reminders") !== -1 && scope.indexOf("st2.closecase") === -1) this.$router.push('/home');
      if (val.indexOf("licenses") !== -1 && scope.indexOf("st2.showlicenses") === -1) this.$router.push('/home');
      if (val.indexOf("accounting") !== -1 && scope.indexOf("st2.viewlist") === -1) this.$router.push('/home');
      if (val.indexOf("reports") !== -1 && scope.indexOf("st2.showreporting") === -1) this.$router.push('/home');
      if (val.indexOf("manager-scheduler/user") !== -1 && scope.indexOf("st2.showschedules") === -1) this.$router.push('/home');
      if (val.indexOf("insured") !== -1 && scope.indexOf("st2.showinsured") === -1) this.$router.push('/home');
      if (val.indexOf("clients") !== -1 && scope.indexOf("st2.showclients") === -1) this.$router.push('/home');
      if (val.indexOf("mycases") !== -1 && this.UserType !== "User") this.$router.push('/home');
      if (val.indexOf("hr") !== -1 && scope.indexOf("st2.showhr") === -1) this.$router.push('/home');
    },

    signOut() {
      clearInterval(this.refreshInterval)
      this.$oidc.logout()
    },

    AcknowledgeNotification() {
      this.closeNotificationToId(this.Acknowledges[0].NotificationID)
    },

    showAcknowledgesNotifications() {
      this.$bvModal.show("modal-acknowledge");
    },

    viewProfile() {
      this.$router.push('/view-profile')
    },
    pushSelected(selected) {
      if (!this.pills.includes(selected)) {
        this.pills.push(selected)
      }
    },
    remove(item) {
      this.pills.splice(this.pills.indexOf(item), 1)
    },
    toggleSearch() {
      this.setShowMainSearch(!this.getShowMainSearch);
    },
    toggleNotification() {
      this.showNotification = !this.showNotification;
      this.getFreshNotification();
    },
    handleMainSearchItem() {
      if (this.mainSearchCategory && window.location.pathname !== '/search-result') {
        this.$router.push('/search-result')
      } else {
        this.$forceUpdate()
      }
    },
    dismissAll() {
      axios
          .post("/api/notifications/aknowledge")
          .then(() => {
            this.getFreshNotification()
          })
    },
    getFreshNotification() {
      if (!this.$store.state.auth.IsLoggedIn || !navigator.onLine) { return; }
      axios
          .get('/api/notifications/user')
          .then(res => {
            this.Notifications = res.data.filter(i => !i.UserMustAcknowledge);
            this.Acknowledges = res.data.filter(i => i.UserMustAcknowledge);
            store.commit('notification/setNotification', this.Notifications)
          })
          .catch(error => {
              if(error.response && error.response.data) {
                  if (error.response.data.indexOf("Invalid auth token") !== -1) {
                      console.log("Invalid auth token")
                      this.signOut()
                  }  else if (error.response.status === 401) {
                      console.log("status 401")
                      this.signOut()
                  }
              }
          })
    },
    closeNotificationToId(notificationId) {
      this.Notifications = this.Notifications.filter(i => i.NotificationID !== notificationId)
      axios
        .post("/api/notifications/aknowledge?notificationId=" + notificationId)
        .then(() => {
           this.getFreshNotification();
        })

    },
    updateNotifications() {
      setTimeout(() => {
        this.getFreshNotification();
        this.updateNotifications();
      }, 60000);
    },

    onHide(evt) {
      if(evt.trigger === "backdrop"){
        evt.preventDefault();
      }
      if(evt.trigger === "cancel"){
        evt.preventDefault();
        this.refreshToken();
      }
    },

    startTimer(duration) {
      const _this = this;
      let timer = duration, minutes, seconds;
      const countdownTimer = setInterval(() => {
        if (localStorage.getItem('expires') > 500) {
            _this.setSessionEnds(false);
            clearInterval(countdownTimer)
            _this.$bvModal.hide("modal-session-expiring");
            return
        }
        minutes = parseInt(timer / 60, 10);
        seconds = parseInt(timer % 60, 10);
        minutes = minutes < 10 ? "0" + minutes : minutes;
        seconds = seconds < 10 ? "0" + seconds : seconds;

        _this.countdown = minutes + ":" + seconds;

        if (--timer <= 0) {
          _this.$bvModal.hide("modal-session-expiring");
          _this.setSessionEnds(false);
          _this.signOut()
          clearInterval(countdownTimer)
        }
      }, 1000);
    },

    async showModal(val) {
      if (val <= 0) {
        this.$bvModal.hide("modal-session-expiring");
        this.setSessionEnds(false);
        this.signOut()
        return;
      }
      this.setSessionEnds(true);
      this.$bvModal.show("modal-session-expiring");
      this.authUser = await this.$oidc.isLoggedIn();
      if (this.authUser) {
          this.startTimer(val);
      } else {
          this.signOut()
      }
    },

    refreshToken() {
      if ((localStorage.getItem('expires') && localStorage.getItem('sessionTimeout') &&
          Number(localStorage.getItem('expires')) > (Number(localStorage.getItem('sessionTimeout')) - 600)) || !this.onLine) return
      axios
          .post("/api/v2/auth/refresh-token")
          .then(res => {
            if (res) {
              localStorage.setItem('expires',  res.data.expiresIn);
              localStorage.setItem('sessionTimeout',  res.data.sessionTimeout);
              this.setExpires(res.data.expiresIn)
              this.setSessionEnds(false);
              setTimeout(() => {
                this.$bvModal.hide("modal-session-expiring");
              }, 200)
            } else {
              this.signOut()
            }
          })
          .catch(() => {
              this.signOut()
          })
    }
  },
}
</script>

<style>
.nav-item.has-sub .nav-item.has-sub li svg {
    display: flex;
}
.notification-bar {
  position: absolute;
  top: 5rem;
  right: 1rem;
  width: 30rem;
  background: #BEF8FF;
}
.notification-item {
  border: 1px solid;
  padding: 1rem;
}
.bell-icon {
  display: inline-block;
  height: 2rem;
  width: 2rem;
  background-color: #00CFE8;
  border-radius: 50%;
  padding: 0.25rem;
}
.title-notification {
  padding-left: 0.7rem;
  color: #00CFE8;
  font-weight: 600;
}
.text-notification {
  font-size: 12px;
  line-height: 18px;
  color: #706b7b;
}
.close-btn {
  display: inline-block;
  transform: rotate(45deg);
  cursor: pointer;
  font-size: 1.5rem;
  color: #6E6B7B;
}
.notification-date {
  color: #B9B9C3;
}
.notification-dismiss-all {
  border: 1px solid;
  background-color: white;
  padding-top: 0.5rem;
  color: #EA5455;
}
.notification-counter {
  background-color: red;
  color: white;
  padding-top: 0.1rem;
  height: 1.5rem;
  width: 1.5rem;
  position: absolute;
  top: 0;
  margin-left: 0.6rem;
  border-radius: 50%;
  font-size: 1rem;
  text-align: center;
}
.nav {
  flex-wrap: nowrap !important;
}
.nav-link.dropdown-toggle.disabled.d-flex.align-items-center.dropdown-user-link > svg {
  color: #77ecd2 !important;
}
.nav-link.dropdown-toggle.disabled {
  background-color: #476dae !important;
}
</style>
