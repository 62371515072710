<template>
  <b-row class="pt-1 pb-1">
    <b-col>
      <b-row>
        <b-col>
          <b-row>
            <b-col md="2">
              <b-form-group
                label="Sex"
                label-for="sex"
              >
                <v-select
                  id="sex"
                  v-model="Gender"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="title"
                  :options="sexOptions"
                  placeholder=""
                  name="sex"
                  :disabled="readOnly"
                />
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                label="Height"
                label-for="height"
              >
                <div class="d-flex">
                  <span class="d-flex align-items-baseline height-input">
                    <b-form-input
                      id="height"
                      v-model="HeightFt"
                      placeholder=""
                      name="heightFt"
                      class="mr-1"
                      :readonly="readOnly"
                    />
                    <p>ft</p>
                  </span>
                  <span class="d-flex align-items-baseline height-input">
                    <b-form-input
                      v-model="HeightIn"
                      placeholder=""
                      name="heightIn"
                      class="mr-1 ml-1"
                      :readonly="readOnly"
                    />
                    <p>in</p>
                  </span>
                </div>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                label="Weight"
                label-for="weight"
              >
                <b-form-input
                  id="weight"
                  v-model="Weight"
                  placeholder=""
                  name="weight"
                  :readonly="readOnly"
                />
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                label="Age (calculated from DOB)"
                label-for="age"
              >
                <b-form-input
                  id="age"
                  v-model="Age"
                  placeholder=""
                  name="age"
                  :readonly="true"
                />
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                label="Married"
                label-for="married"
              >
                <b-form-input
                  id="married"
                  v-model="Spouse"
                  placeholder=""
                  name="married"
                  :readonly="readOnly"
                />
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                label="Children"
                label-for="children"
              >
                <b-form-input
                  id="children"
                  v-model="Children"
                  placeholder=""
                  name="children"
                  :readonly="readOnly"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row class="pb-1">
            <b-col>
              <b-form-group
                label="Race"
                label-for="race"
              >
                <b-form-input
                  id="race"
                  v-model="Race"
                  placeholder=""
                  name="race"
                  :readonly="readOnly"
                />
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                label="Hair Color"
                label-for="hairColor"
                class="text-nowrap"
              >
                <b-form-input
                  id="hairColor"
                  v-model="Hair"
                  placeholder=""
                  name="hairColor"
                  :readonly="readOnly"
                />
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                label="Hair Style"
                label-for="hairStyle"
                class="text-nowrap"
              >
                <b-form-input
                  id="hairStyle"
                  v-model="HairStyle"
                  placeholder=""
                  name="hairStyle"
                  :readonly="readOnly"
                />
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                label="Eye Color"
                label-for="eyeColor"
                class="text-nowrap"
              >
                <b-form-input
                  id="eyeColor"
                  v-model="Eyes"
                  placeholder=""
                  name="eyeColor"
                  :readonly="readOnly"
                />
              </b-form-group></b-col>
            <b-col>
              <b-form-group
                label="Glasses"
                label-for="glasses"
              >
                <b-form-input
                  id="glasses"
                  v-model="Glasses"
                  placeholder=""
                  name="glasses"
                  :readonly="readOnly"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="3">
          <p>Other Characteristics (if different than above)</p>
        </b-col>
        <b-col>
          <div
            class="collapse-textarea"
            :class="{expanded: !isExpandedOtherCharacteristics}"
          >
            <textarea
              v-model="OtherCharacteristics"
              class="expandable-textarea"
              :class="[isExpandedOtherCharacteristics ? 'not-expanded' : 'expanded']"
              contentEditable
              :readonly="readOnly"
            />
            <div @click="isExpandedOtherCharacteristics = !isExpandedOtherCharacteristics">
              <span
                class="expand-arrow mt-n1"
                :class="[isExpandedOtherCharacteristics ? 'not-expanded' : 'expanded']"
              >&#187;</span>
            </div>
          </div>
        </b-col>
      </b-row>
      <b-row class="pt-1 pb-1">
        <b-col md="3">
          <p>Known Vehicles</p>
        </b-col>
        <b-col>
          <div
            class="collapse-textarea"
            :class="{expanded: !isExpandedKnownVehicles}"
          >
            <textarea
              v-model="VehicleInformation"
              class="expandable-textarea"
              :class="[isExpandedKnownVehicles ? 'not-expanded' : 'expanded']"
              contentEditable
              :readonly="readOnly"
            />
            <div @click="isExpandedKnownVehicles = !isExpandedKnownVehicles">
              <span
                class="expand-arrow mt-n1"
                :class="[isExpandedKnownVehicles ? 'not-expanded' : 'expanded']"
              >&#187;</span>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import {BCol, BFormGroup, BFormInput, BFormTextarea, BRow} from "bootstrap-vue";
import vSelect from "vue-select";
import {mapGetters} from "vuex";

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    vSelect,
  },
  data() {
    return {
      SubjectDescription: null,
      Gender: "",
      Height: "",
      Weight: "",

      Race: "",
      Hair: "",
      HairStyle: "",
      Eyes: "",
      Glasses: "",
      OtherCharacteristics: "",
      VehicleInformation: "",
      Spouse: "",

      Age: "",
      Children: "",

      HeightFt: 0,
      HeightIn: 0,

      sexOptions: ["Male", "Female"],

      isExpandedKnownVehicles: false,
      isExpandedOtherCharacteristics: false,
      readOnly: false,
    }
  },
  computed: {
    ...mapGetters({
      getSubject: "cases/getSubject",
      getIsReadOnly: "cases/getIsReadOnly",
      getCurrentScopes: "scopes/getCurrentScopes",
      getAllSubjectTabOpen: "cases/getAllSubjectTabOpen",
    }),
    getSubjectData() {
      return this.getSubject
    },
    isReadOnly() {
      return this.getIsReadOnly;
    },
  },
  watch: {
    getAllSubjectTabOpen: {
      immediate: true,
      handler(value) {
        const val = !!value
        this.isExpandedKnownVehicles = val
        this.isExpandedOtherCharacteristics = val
      }
    },
    getSubjectData(val) {
      this.fillSubject(val);
    },
    isReadOnly(val) {
      this.readOnly = val
    },
    Gender(val) {this.SubjectDescription = {...this.SubjectDescription, Gender: val}},
    Weight(val) {this.SubjectDescription = {...this.SubjectDescription, Weight: val}},

    Race(val) {this.SubjectDescription = {...this.SubjectDescription, Race: val}},
    Hair(val) {this.SubjectDescription = {...this.SubjectDescription, Hair: val}},
    HairStyle(val) {this.SubjectDescription = {...this.SubjectDescription, HairStyle: val}},
    Eyes(val) {this.SubjectDescription = {...this.SubjectDescription, Eyes: val}},
    Glasses(val) {this.SubjectDescription = {...this.SubjectDescription, Glasses: val}},
    OtherCharacteristics(val) {this.SubjectDescription = {...this.SubjectDescription, OtherCharacteristics: val}},
    VehicleInformation(val) {this.SubjectDescription = {...this.SubjectDescription, VehicleInformation: val}},
    Children(val) {this.SubjectDescription = {...this.SubjectDescription, Children: val}},
    Spouse(val) {this.SubjectDescription = {...this.SubjectDescription, Spouse: val}},

    HeightFt() {this.fillHeight()},
    HeightIn() {this.fillHeight()},

    SubjectDescription(val) {
      this.$emit("changedSubjectDescription", val);
    },
  },
  async created() {
    this.readOnly = this.getIsReadOnly;
    try {
      this.fillSubject(this.getSubject);
    } catch (err) {
      this.error = err
    }
  },
  methods: {
    fillSubject(val) {
      this.Gender = val.Gender;
      this.Height = val.Height;
      this.Weight = val.Weight;
      this.Spouse = val.Spouse;
      this.Children = val.Children;
      this.Race = val.Race;
      this.Hair = val.Hair;
      this.HairStyle = val.HairStyle;
      this.Eyes = val.Eyes;
      this.Glasses = val.Glasses;
      this.OtherCharacteristics = val.OtherCharacteristics;
      this.VehicleInformation = val.VehicleInformation;
      this.Age = this.getCurrentScopes.indexOf('st2.viewssnanddob') !== -1 ?
          this.fillAge(val.DateOfBirth, val.DateOfBirthText) : val.Age
      this.splitHeight();

    },
    splitHeight() {
      if (this.Height.indexOf(" ft ") !== -1) {
        const heightAr = this.Height.split(" ft ");
        this.HeightFt = heightAr[0];
        this.HeightIn = heightAr[1];
      }
    },
    fillHeight() {
      this.Height = this.HeightFt + " ft " + this.HeightIn;
      this.SubjectDescription = {...this.SubjectDescription, Height: this.Height}
    },
    fillAge(DateOfBirth, DateOfBirthText) {
      if (new Date(DateOfBirth).toString().indexOf("Date") !== -1 || !DateOfBirth) {
        console.log("Invalid time value")
        return ""
      }
      const stringArr = DateOfBirth.split("/")[0];
      if (stringArr === "00" || this.getCurrentScopes.indexOf('st2.viewssnanddob') === -1) {
        return ""
      }
      const currentDate = new Date();
      const currentYear = currentDate.getFullYear();
      const currentMonth = currentDate.getMonth();
      const currentDay = currentDate.getDate();
      let DOB = new Date();
      if (DateOfBirth) {
        DOB = new Date(DateOfBirth)
      }
      if (DateOfBirthText) {
        DOB = new Date(DateOfBirthText)
      }
      const YOB = DOB.getFullYear()
      const MOB = DOB.getMonth()
      const DayOB = DOB.getDate()
      if (currentMonth <= MOB && currentDay < DayOB) {
        return currentYear - YOB - 1;
      }
      return currentYear - YOB;
    },
  },
}
</script>

<style scoped>
.mr-05 {
  margin-right: 0.5rem;
}
.ml-05 {
  margin-left: 0.5rem;
}
.height-input input {
  padding-left: 0.2rem;
  padding-right: 0.2rem;
  width: 3.5rem;
}
p {
  font-size: .857rem;
}
</style>