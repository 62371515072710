<template>
  <div>
    <b-row>
      <b-col
        md="3"
        class="p-1"
      >
        Case Number
      </b-col>
      <b-col
        md="3"
        class="p-1"
      >
        Subject
      </b-col>
    </b-row>
    <b-row
      v-for="item of rows"
      :key="item.CaseID"
    >
      <b-col
        md="3"
        class="p-1 cursor-pointer btn-link"
        style="margin-bottom: 3px; text-decoration: underline; font-weight: bold"
        @click="goToCase(item.CaseID)"
      >
        {{ item.FileNumber }}
      </b-col>
      <b-col
        md="3"
        class="p-1 cursor-pointer"
        style="margin-bottom: 3px"
      >
        <router-link
          :to="'/subject/' + item.PrimarySubjectID"
        >
          {{ item.SubjectName }}
        </router-link>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {BCol, BRow} from "bootstrap-vue";
import APIService from "@core/utils/APIService";

const apiService = new APIService();

export default {
  components: {
    BRow,
    BCol,
  },
  data() {
    return {
      rows: [],
    }
  },
  created() {
    this.getRelated();
  },
  methods: {
    goToCase(caseID) {
      this.$router.push('/cases/' + caseID + '/detail').then(() => {
        this.getRelated();
        window.location.reload();
      });
    },
    getRelated() {
      const CaseID = this.$route.params.caseId;
      if (CaseID) {
        apiService
            .get("case/" + CaseID + "/related")
            .then(res => {
              this.rows = res.data;
            })
      }
    }
  }
}
</script>

<style scoped>

</style>